import "./Player.scss";
import { Stack, Divider } from "@mui/material";
import { useState } from "react";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import ElderlyOutlinedIcon from "@mui/icons-material/ElderlyOutlined";
import CopyrightIcon from "@mui/icons-material/Copyright";
function Player(props) {
  const [showPlayerDetail, setShowPlayerDetail] = useState(false);
  const convertToMad = (market_value) => {
    return isNaN(parseFloat(market_value) / 1000)
      ? "-"
      : parseFloat(market_value) / 1000;
  };
  return (
    <div>
      <div
        className={"player "}
        onMouseEnter={() => setShowPlayerDetail(true)}
        onMouseLeave={() => setShowPlayerDetail(false)}
        onClick={(e) => {
          e.stopPropagation();
          props.setHighlight(props.position);
          props.setSelectedPlayer({
            position: props.position,
            player: props.squadSkeleton ?? null,
          });
          props.setPosition(props.abstractPosition);
        }}
      >
        <img
          className={
            props.highlight === props.position ? "player-selected" : null
          }
          src={
            props.squadSkeleton?.player_team
              ? "./" + props.squadSkeleton?.player_team + ".png"
              : "./jersey.png"
          }
          style={{ width: "100px" }}
          loading="lazy"
        />
        {!props.squadSkeleton?.player_name && (
          <Stack direction={"column"}>
            <div className="player-name-placeholder">{props.placeholder}</div>
          </Stack>
        )}
        {props.squadSkeleton?.player_name && (
          <Stack
            sx={{ alignItems: "center" }}
            direction={"column"}
            onClick={() => {
              props.squadSkeleton?.player_transfertmarkt_id &&
                props.handleShowDetails(props.squadSkeleton, true);
            }}
          >
            <div className="player-name">
              {props.squadSkeleton?.player_name.substring(
                props.squadSkeleton?.player_name.indexOf(" ") + 1
              )}
            </div>
            <div className="player-club">
              {!showPlayerDetail ? (
                <div className="player-glimpse">
                  {props.isCaptain === true ? (
                    <CopyrightIcon size="small"></CopyrightIcon>
                  ) : (
                    <ShieldOutlinedIcon size="small"></ShieldOutlinedIcon>
                  )}
                  <span>{props.squadSkeleton?.player_team}</span>
                </div>
              ) : (
                <div className="player-glimpse">
                  <AttachMoneyIcon size="small"></AttachMoneyIcon>
                  <span>{convertToMad(props.squadSkeleton?.market_value)}</span>
                  <Divider variant={"middle"}></Divider>
                  <ElderlyOutlinedIcon size="small"></ElderlyOutlinedIcon>
                  <span>{props.squadSkeleton?.age}</span>
                </div>
              )}
            </div>
          </Stack>
        )}
      </div>
    </div>
  );
}

export default Player;
