import "./Match.scss";
import axios from "axios";
import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  Input,
  Link,
  Card,
  Button,
  FormControl,
  InputLabel,
  Alert,
  Divider,
  Chip,
  List,
  IconButton,
  ListItem,
  Popover,
  Autocomplete,
  Snackbar,
  TextField,
  useRadioGroup,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import PaidIcon from "@mui/icons-material/Paid";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Player from "./Player";
import { Routes, Route, useParams, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { mainUrl } from "../../env";
import PlayerDrawer from "./PlayerDrawer";
import CardDrawer from "./CardDrawer";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Fixtures from "./Fixtures";
import io from "socket.io-client";
import useToken from "../../useToken";
import StyleIcon from "@mui/icons-material/Style";
import {
  StatsBadgeNoteSmall,
  StatsBadgeGauge,
  StatsBadgeGaugeRound,
} from "./StatsBadge";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import CloseIcon from "@mui/icons-material/Close";
import SensorWindowIcon from "@mui/icons-material/SensorWindow";
import TimeCounter from "./TimeCounter";
import CustomizedTimeline from "./MatchTimeline";
import { useSnackbar } from "notistack";
import {
  EventDisplayGoal,
  EventDisplayYellowCard,
  EventDisplayRedCard,
} from "./EventDisplay";
const squadSkeleton = {
  goalkeeper_1: {},
  defense_1: {},
  defense_2: {},
  defense_3: {},
  defense_4: {},
  milieu_1: {},
  milieu_2: {},
  milieu_3: {},
  milieu_4: {},
  attaque_1: {},
  attaque_2: {},
  goalkeeper_sub: {},
  defense_sub: {},
  milieu_sub: {},
  attaque_sub: {},
  captain: {},
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const shapeStyles = {
  backgroundColor: "green",
  width: "30px",
  height: "30px",
  display: "block",
  margin: "10px",
};
const invisibleShapeStyles = {
  width: "30px",
  height: "30px",
  display: "block",
  margin: "10px",
};
const shapeCircleStyles = { borderRadius: "50%" };
const circle = (
  <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }} />
);
const invisibleCircle = (
  <Box
    component="span"
    sx={{ ...invisibleShapeStyles, ...shapeCircleStyles }}
  />
);
function Match(props) {
  const [position, setPosition] = useState("Goalkeeper");
  const [userCards, setUserCards] = useState([]);
  const [user, setUser] = useState(null);
  const [players, setPlayers] = useState([]);
  const [cards, setCards] = useState([]);
  const [card, setCard] = useState(null);
  const [showStats, setShowStats] = useState(false);
  const [stats, setStats] = useState(null);
  const [squad, setSquad] = useState(squadSkeleton);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [playerIn, setPlayerIn] = useState(null);
  const [highlight, setHighlight] = useState("");
  const [teamName, setTeamName] = useState("");
  const [budget, setBudget] = useState("");
  const [homePlayedCards, setHomePlayedCards] = useState([]);
  const [awayPlayedCards, setAwayPlayedCards] = useState([]);

  const [homeScore, setHomeScore] = useState(0);
  const [awayScore, setAwayScore] = useState(0);
  const [yellowCardHome, setYellowCardHome] = useState(0);
  const [yellowCardAway, setYellowCardAway] = useState(0);
  const [redCardHome, setRedCardHome] = useState(0);
  const [redCardAway, setRedCardAway] = useState(0);
  const [showCaptainSelection, setShowCaptainSelection] = useState(false);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [value, setValue] = useState(0);
  const [showCardDetail, setShowCardDetail] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [matchResult, setMatchResult] = useState(true);
  const [openResult, setOpenResult] = useState(false);
  const [socket, setSocket] = useState(null);
  const [updatedTeamStrength, setUpdatedTeamStrength] = useState(null);
  const [awayTeam, setAwayTeam] = useState({ squad: squadSkeleton });
  const [homeTeam, setHomeTeam] = useState(squadSkeleton);
  const [counter, setCounter] = useState(0);
  const [playerTurn, setPlayerTurn] = useState(0);
  const [subIn, setSubIn] = useState(0);
  const [subOut, setSubOut] = useState(0);
  const [showSubDialog, setShowSubDialog] = useState(false);
  const [messages, setMessages] = useState([]);
  const [rounds, setRounds] = useState(0);
  const navigate = useNavigate();
  const { token, setToken } = useToken();
  const [alignment, setAlignment] = useState("data");
  const [alignmentHome, setAlignmentHome] = useState("dataHome");
  const [hasSubs, setHasSubs] = useState(false);
  const [matchStarted, setMatchStarted] = useState(false);
  const [playerJoined, setPlayerJoined] = useState(false);
  const [goalHome, setGoalHome] = useState(false);
  const [goalAway, setGoalAway] = useState(false);
  const [rCardHome, setRCardHome] = useState(false);
  const [yCardHome, setYCardHome] = useState(false);
  const [rCardAway, setRCardAway] = useState(false);
  const [yCardAway, setYCardAway] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    axios.defaults.headers.common["x-auth-token"] = token;
  }, [token]);

  const handleChangeView = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleChangeViewHome = (event, newAlignment) => {
    setAlignmentHome(newAlignment);
  };

  const matchStatus = () => {
    let msg;
    if (user?.id == homeTeam?.created_by) {
      if (updatedTeamStrength?.home.milieu < updatedTeamStrength?.away.milieu)
        msg =
          "On est dominé au milieu, il faut presser d'avantage si on veut marquer";

      if (updatedTeamStrength?.home.defense < 50)
        msg =
          "On risque de prendre un but si notre note deéfense ne s'améliore pas";

      if (updatedTeamStrength?.home.defense > 70)
        msg = "Notre défense est devenu trés agressive on risque des cartons";
    }
    if (user?.id == awayTeam?.created_by) {
      if (updatedTeamStrength?.away.milieu < updatedTeamStrength?.home.milieu)
        msg =
          "On est dominé au milieu, il faut presser d'avantage si on veut marquer";

      if (updatedTeamStrength?.away.defense < 50)
        msg =
          "On risque de prendre un but si notre note deéfense ne s'améliore pas";

      if (updatedTeamStrength?.away.defense > 70)
        msg = "Notre défense est devenu trés agressive on risque des cartons";
    }
    if (msg && msg != messages[messages.length - 1])
      setMessages((current) => [...current, msg]);
  };

  useEffect(() => {
    matchStatus();
  }, [updatedTeamStrength]);
  useEffect(() => {
    if (rounds == 19) {
      socket.emit("matchEnded", params.match_id, user?.id);
      alert("Coup de sifflet final");
      navigate("/");
    }
  }, [rounds]);

  //when match done   setOpenResult(true);
  let params = useParams();

  const startMatch = async () => {
    setMatchStarted(true);
    socket.emit("start", params.match_id, user?.id);

    socket.on("start", async function (data) {
      if (data.isFinished) navigate("/");
      if (data.teamStrength != []) {
        await setUpdatedTeamStrength(data.teamStrength);
      }
      await setHomeScore(data.scoreHome);
      await setAwayScore(data.scoreAway);
      await setYellowCardHome(data.yellowCardHome);
      await setYellowCardAway(data.yellowCardAway);
      await setRedCardHome(data.redCardHome);
      await setRedCardAway(data.redCardAway);
      await setAwayTeam(data.awayTeam);
      await setHomeTeam(data.homeTeam);
      await setHomePlayedCards(data.cards_played_home);
      await setAwayPlayedCards(data.cards_played_away);
      await setSquad(data.homeTeam.squad);
    });
    socket.on("your_turn", async function (playerId, matchForfeited) {
      setPlayerJoined(true);
      await setPlayerTurn(playerId);
      await setRounds((prevrounds) => prevrounds + 1);
      if (matchForfeited) {
        alert("Adversaire déclare forfait match gagné 3-0");
        navigate("/");
      }
    });
  };
  const applyStrategy = async (myCard) => {
    socket.emit(
      "playCard",
      myCard,
      user.id,
      params.match_id,
      updatedTeamStrength,
      rounds
    );

    socket.on("playCard", async function (data) {
      let newDeck;

      if (data.result.scoreHome > homeScore) {
        await setGoalHome(true);
        setTimeout(() => setGoalHome(false), 6000);
      }

      if (data.result.yellowCardHome > yellowCardHome) {
        await setYCardHome(true);
        setTimeout(() => setYCardHome(false), 4000);
      }

      if (data.result.redCardHome > redCardHome) {
        await setRCardHome(true);
        setTimeout(() => setRCardHome(false), 2000);
      }

      if (data.result.scoreAway > awayScore) {
        await setGoalAway(true);
        setTimeout(() => setGoalAway(false), 6000);
      }
      if (data.result.yellowCardAway > yellowCardAway) {
        await setYCardAway(true);
        setTimeout(() => setYCardAway(false), 4000);
      }
      if (data.result.redCardAway > redCardAway) {
        await setRCardAway(true);
        setTimeout(() => setRCardAway(false), 2000);
      }

      if (data.homeTeam.created_by == user.id) {
        newDeck = cards.filter(
          (a) => !data.result.cards_played_home.map((b) => b.id).includes(a.id)
        );
      }
      if (data.awayTeam.created_by == user.id) {
        newDeck = cards.filter(
          (a) => !data.result.cards_played_away.map((b) => b.id).includes(a.id)
        );
      }
      await setCards(newDeck);
      await setUpdatedTeamStrength(data.result.teamStrength);
      await setHomeScore(data.result.scoreHome);
      await setAwayScore(data.result.scoreAway);
      await setYellowCardHome(data.result.yellowCardHome);
      await setYellowCardAway(data.result.yellowCardAway);
      await setRedCardHome(data.result.redCardHome);
      await setRedCardAway(data.result.redCardAway);
      await setHomePlayedCards(data.result.cards_played_home);
      await setAwayPlayedCards(data.result.cards_played_away);
      await setAwayTeam(data.awayTeam);
      await setHomeTeam(data.homeTeam);
      await setSquad(data.homeTeam.squad);
    });
    socket.on("substitution", async function (data) {
      if (data.teamStrength.away.subs)
        enqueueSnackbar("Visiteur:" + data.teamStrength.away.subs, {
          autoHideDuration: 3000,
        });
      if (data.teamStrength.home.subs)
        enqueueSnackbar("Domicile:" + data.teamStrength.home.subs, {
          autoHideDuration: 3000,
        });
      if (data.teamStrength != []) {
        await setUpdatedTeamStrength(data.teamStrength);
      }
    });
    console.log("Play card");
    await handlePopoverClose();
  };

  const handleCloseSub = async () => {
    await setShowSubDialog(false);
  };
  const handleSubstitution = async (position) => {
    await setSubIn(position);
    for (const key in squad) {
      if (
        squad[position].player_position == squad[key].player_position &&
        squad[position].player_transfertmarkt_id !=
          squad[key].player_transfertmarkt_id
      ) {
        await setSubOut(key);
        break;
      }
    }
    await setShowSubDialog(true);
  };
  const substitutePlayer = async () => {
    let temp;
    temp = squad[subOut];
    squad[subOut] = squad[subIn];
    squad[subIn] = temp;
    await setSquad(squad);
    await setShowSubDialog(false);

    let strength = updatedTeamStrength;

    if (user?.id == homeTeam?.created_by) {
      if (strength?.home && !strength?.home.subs) {
        if (subIn.includes("goalkeeper")) {
          strength.home.defense = strength.home.defense + 5;
          strength.home.milieu = strength.home.milieu + 5;
          strength.home.attack = strength.home.attack + 5;
          strength.home.subs = "Changement Gardien de but";
        }
        if (subIn.includes("defense")) {
          strength.home.defense = strength.home.defense + 10;
          strength.home.subs = "Changement défensif";
        }
        if (subIn.includes("milieu")) {
          strength.home.milieu = strength.home.milieu + 10;
          strength.home.subs = "Changement milieu de terrain";
        }
        if (subIn.includes("attaque")) {
          strength.home.attack = strength.home.attack + 10;
          strength.home.subs = "Changement offensif";
        }
        await setHasSubs(strength.home.subs);
      }
    }
    if (user?.id == awayTeam?.created_by) {
      if (strength?.away && !strength?.away.subs) {
        if (subIn.includes("goalkeeper")) {
          strength.away.defense = strength.away.defense + 5;
          strength.away.milieu = strength.away.milieu + 5;
          strength.away.attack = strength.away.attack + 5;
          strength.away.subs = "Changement Gardien de but";
        }
        if (subIn.includes("defense")) {
          strength.away.defense = strength.away.defense + 10;
          strength.away.subs = "Changement défensif";
        }
        if (subIn.includes("milieu")) {
          strength.away.milieu = strength.away.milieu + 10;
          strength.away.subs = "Changement milieu de terrain";
        }
        if (subIn.includes("attaque")) {
          strength.away.attack = strength.away.attack + 10;
          strength.away.subs = "Changement offensif";
        }
        await setHasSubs(strength.away.subs);
      }
    }

    socket.emit("substitution", params.match_id, strength);
  };

  const handlePopoverOpen = async (event, myCard) => {
    setAnchorEl(event.currentTarget);
    await setCard(myCard);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const addCard = (strategy) => {
    if (userCards.filter((c) => strategy.id === c.id).length > 0) {
      alert("You cannot have the same strategy twice");
      return;
    } else if (userCards?.length === 9) {
      alert("You already have 9 cards");
      return;
    } else {
      let tempDeck = userCards;
      tempDeck.push(strategy);
      setUserCards(tempDeck);
      setShowCardDetail(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleDeleteCard = (cardToBeremoved) => {
    setUserCards(userCards.filter((Card) => cardToBeremoved.id !== Card.id));
  };

  const getBudget = () => {
    let team_value = 0;
    for (const key in squad) {
      if (key != "captain")
        team_value = team_value + convertToMad(squad[key].market_value ?? 0);
    }
    return 7000 - team_value;
  };

  const canBuyPlayer = (player, position) => {
    let team_value = 0;
    for (const key in squad) {
      if (position != key)
        team_value = team_value + convertToMad(squad[key].market_value ?? 0);
    }

    return team_value + convertToMad(player.market_value) <= 7000;
  };
  const convertToMad = (market_value) => {
    return parseFloat(market_value) / 1000;
  };
  const handleTeamName = (e) => {
    setTeamName(e.target.value);
  };

  const switchPlayer = async (data) => {
    if (data.captain) {
      let tempSquad = squad;
      tempSquad["captain"] = data.captain.player;
      await setSquad(tempSquad);
      await setShowStats(false);
      return;
    }

    if (
      !playerAlreadySelected(squad, data.player) &&
      canBuyPlayer(data.player, data.position)
    ) {
      let tempSquad = squad;
      tempSquad[data.position] = data.player;

      setSelectedPlayer(data.player);
      await setSquad(tempSquad);
      await setBudget(getBudget());
    } else {
      alert("player already selected or budget dépensé");
    }
    await setShowStats(false);
  };

  const playerAlreadySelected = (squad, player) => {
    for (let key in squad) {
      if (squad[key].player_name === player?.player_name) {
        return true;
      }
    }
    return false;
  };

  const cancelSave = () => {
    setTeamName("");
    setShowSaveDialog(false);
  };

  const saveTeam = async () => {
    await axios
      .post(mainUrl + "/api/fantasy/setSquad", {
        squad: squad,
        team_name: teamName,
      })
      .then((res) => {
        console.log(res.data);
      });
  };

  const handlePositionChange = async (value) => {
    if (!value) return;
    await setPosition(value);
    axios
      .get(mainUrl + "/api/players/getPlayersByPosition?position=" + value, {})
      .then((res) => {
        const players = res.data.players;
        setPlayers(players);
      });
  };
  const handleShowDetails = async (player, isIn = false) => {
    if (!player) return;
    if (!isIn) setPlayerIn(player);
    if (isIn) {
      setShowCaptainSelection(player);
    }
  };

  useEffect(() => {
    axios.get(mainUrl + "/api/users/me", {}).then((res) => {
      if (res.status == 200) {
        const user = res.data.user;
        setUser(user);
      } else {
        Navigate("/");
      }
    });
    axios.get(mainUrl + "/api/fantasy/getSquad", {}).then(async (res) => {
      if (res.data.squad) {
        await setSquad(res.data.squad);
      }
      if (res.data.team_name) {
        await setTeamName(res.data.team_name);
      }
    });
    axios
      .get(
        mainUrl + "/api/players/getPlayersByPosition?position=" + position,
        {}
      )
      .then((res) => {
        const players = res.data.players;
        setPlayers(players);
      });

    axios.get(mainUrl + "/api/cards/getUserCards", {}).then((res) => {
      const cards = res.data.cards;
      setCards(cards);
    });
  }, []);

  useEffect(() => {
    try {
      if (user && token) {
        const newSocket = io(`http://${window.location.hostname}:8080`, {
          extraHeaders: {
            "x-auth-token": token,
          },
          query: "matchId=" + params.match_id + "&playerId=" + user?.id,
        });
        newSocket.on("news", function (msg) {
          console.log(msg);
        });

        setSocket(newSocket);

        return () => newSocket.close();
      }
    } catch (e) {
      console.log(e);
    }
  }, [user, token]);

  return (
    <Container
      sx={{
        maxWidth: "100% !important",
        alignItems: "flex-start",
        height: "100vh",
      }}
      className="match-container"
      id="match-container"
    >
      <Grid justifyContent="center">
        <Stack>{user?.id == playerTurn && <TimeCounter></TimeCounter>}</Stack>
      </Grid>
      {goalHome && <EventDisplayGoal homeAway={"home"}></EventDisplayGoal>}
      {yCardHome && (
        <EventDisplayYellowCard homeAway={"home"}></EventDisplayYellowCard>
      )}
      {rCardHome && (
        <EventDisplayRedCard homeAway={"home"}></EventDisplayRedCard>
      )}
      {goalAway && <EventDisplayGoal homeAway={"away"}></EventDisplayGoal>}
      {yCardAway && (
        <EventDisplayYellowCard homeAway={"away"}></EventDisplayYellowCard>
      )}
      {rCardAway && (
        <EventDisplayRedCard homeAway={"away"}></EventDisplayRedCard>
      )}
      <Grid
        container
        justifyContent="center"
        className="match-status-container"
        sx={{
          boxShadow: { sm: "0 0 5px #ddd" },
          py: "6rem",
          px: "1rem",
        }}
      >
        <div className="match-status">
          <div>
            <div id="home-team" className="home-team">
              {homeTeam.created_by == playerTurn ? circle : invisibleCircle}
              {homeTeam.team_name}
            </div>
            <div className="match-score">{homeScore}</div>
          </div>
        </div>
        <div className="match-status">
          <div className="match-time">
            <div className="match-time-text">{rounds * 5}:00</div>
            {user?.id &&
              (!matchStarted ? (
                <Button
                  onClick={() => startMatch()}
                  size="large"
                  color="secondary"
                  variant="contained"
                >
                  Commencez!
                </Button>
              ) : playerJoined ? null : (
                "En attente d'adversaire"
              ))}
          </div>
        </div>
        <div className="match-status">
          <div>
            <div className="match-score">{awayScore}</div>
            <div className="away-team">
              {awayTeam.team_name}
              {awayTeam.created_by == playerTurn ? circle : invisibleCircle}
            </div>
          </div>
        </div>

        <Grid
          container
          justifyContent="space-evenly"
          alignItems="flex-start"
          sx={{ width: "100%", height: "100%" }}
        >
          <Grid xs={5} sm={5} direction="column">
            <div className="view-toggle-selection">
              <ToggleButtonGroup
                color="secondary"
                value={alignmentHome}
                exclusive
                onChange={handleChangeViewHome}
              >
                <ToggleButton value="cardHome">Match</ToggleButton>
                <ToggleButton value="dataHome">Stats domicile</ToggleButton>
                {user?.id == homeTeam?.created_by && (
                  <ToggleButton value="capitainHome">
                    Avis capitaine
                  </ToggleButton>
                )}
                <ToggleButton value="teamHome">Equipe domicile</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className="team-name-section-header">{homeTeam.team_name}</div>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ alignSelf: "flex-start" }}
              style={{
                margin: "auto",
                width: "100%",
              }}
              className="pitch-section"
            >
              <Stack
                direction="column"
                alignSelf={"flex-start"}
                justifyContent={"space-evenly"}
              >
                <div className="formation-select">
                  <div className="formation-title">ACTIONS</div>
                  {playerTurn == user?.id &&
                    cards.map((c) => {
                      return (
                        <div
                          onMouseEnter={(e) => handlePopoverOpen(e, c)}
                          onMouseLeave={handlePopoverClose}
                          onClick={() => applyStrategy(c)}
                          className={`formation-strategy formation-strategy__${c.category}`}
                        >
                          {c.card_name.toUpperCase()}
                        </div>
                      );
                    })}
                </div>
              </Stack>

              {alignmentHome == "cardHome" && (
                <div className="timeline-view">
                  <Stack
                    direction="column"
                    alignSelf={"flex-start"}
                    justifyContent={"space-evenly"}
                    alignContent={"center"}
                  >
                    <CustomizedTimeline
                      awayPlayedCards={awayPlayedCards}
                      homePlayedCards={homePlayedCards}
                      handlePopoverOpen={handlePopoverOpen}
                      handlePopoverClose={handlePopoverClose}
                    ></CustomizedTimeline>
                  </Stack>
                </div>
              )}
              {alignmentHome == "capitainHome" && (
                <div className="captain-view">
                  <Player
                    position="Captain"
                    squadSkeleton={squad["captain"]}
                    placeholder={squad["captain"].player_name}
                    isCaptain={true}
                  ></Player>
                  <div className="captain-messages">
                    {messages.map((message) => {
                      return (
                        <Alert className="capitain-voice" severity="info">
                          {message}
                        </Alert>
                      );
                    })}
                  </div>
                </div>
              )}
              {alignmentHome == "dataHome" && (
                <div className="stats-view">
                  <Grid>
                    <StatsBadgeGaugeRound
                      value={updatedTeamStrength?.home.defense ?? 0}
                      category={"Stabilité defensive"}
                    ></StatsBadgeGaugeRound>
                    <StatsBadgeGaugeRound
                      category={"Fluidité de passe"}
                      value={updatedTeamStrength?.home.milieu ?? 0}
                    ></StatsBadgeGaugeRound>
                    <StatsBadgeGaugeRound
                      category={"Probabilité de but"}
                      value={updatedTeamStrength?.home.attack ?? 0}
                    ></StatsBadgeGaugeRound>
                  </Grid>
                  <StatsBadgeNoteSmall
                    category={"Valeur d'équipe"}
                    value={updatedTeamStrength?.home.total_lineup ?? "-"}
                    icon={<PaidIcon fontSize="large"></PaidIcon>}
                  ></StatsBadgeNoteSmall>
                  <StatsBadgeNoteSmall
                    category={"Cartons Jaunes"}
                    value={yellowCardHome ?? 0}
                    icon={
                      <StyleIcon
                        sx={{ color: "yellow" }}
                        color="warning"
                        fontSize="large"
                      ></StyleIcon>
                    }
                  ></StatsBadgeNoteSmall>
                  <StatsBadgeNoteSmall
                    category={"Cartons Rouges"}
                    value={redCardHome ?? 0}
                    icon={
                      <StyleIcon
                        sx={{ color: "red" }}
                        color="warning"
                        fontSize="large"
                      ></StyleIcon>
                    }
                  ></StatsBadgeNoteSmall>
                </div>
              )}

              {alignmentHome == "teamHome" && (
                <>
                  <div
                    className="pitch"
                    style={{ backgroundImage: "url(pitch.svg)" }}
                  >
                    <div className="substitutes-section-header">Titulaires</div>
                    <Stack
                      sx={{ margin: "auto" }}
                      direction="row"
                      justifyContent={"space-evenly"}
                    >
                      <Player
                        position="goalkeeper_1"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={squad["goalkeeper_1"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Goalkeeper"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Gardien de but"
                        isCaptain={
                          squad["goalkeeper_1"].player_transfertmarkt_id ===
                          squad["captain"].player_transfertmarkt_id
                        }
                      ></Player>
                    </Stack>
                    <Stack
                      sx={{ margin: "auto" }}
                      direction="row"
                      justifyContent={"space-evenly"}
                    >
                      <Player
                        position="defense_1"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={squad["defense_1"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Defense"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Défenseur 1"
                        isCaptain={
                          squad["defense_1"].player_transfertmarkt_id ===
                          squad["captain"].player_transfertmarkt_id
                        }
                      ></Player>
                      <Player
                        position="defense_2"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={squad["defense_2"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Defense"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Défenseur 2"
                        isCaptain={
                          squad["defense_2"].player_transfertmarkt_id ===
                          squad["captain"].player_transfertmarkt_id
                        }
                      ></Player>
                      <Player
                        position="defense_3"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={squad["defense_3"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Defense"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Défenseur 3"
                        isCaptain={
                          squad["defense_3"].player_transfertmarkt_id ===
                          squad["captain"].player_transfertmarkt_id
                        }
                      ></Player>
                      <Player
                        position="defense_4"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={squad["defense_4"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Defense"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Défenseur 4"
                        isCaptain={
                          squad["defense_4"].player_transfertmarkt_id ===
                          squad["captain"].player_transfertmarkt_id
                        }
                      ></Player>
                    </Stack>
                    <Stack
                      sx={{ margin: "auto" }}
                      direction="row"
                      justifyContent={"space-evenly"}
                    >
                      <Player
                        position="milieu_1"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={squad["milieu_1"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Milieu"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Milieu 1"
                        isCaptain={
                          squad["milieu_1"].player_transfertmarkt_id ===
                          squad["captain"].player_transfertmarkt_id
                        }
                      ></Player>
                      <Player
                        position="milieu_2"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={squad["milieu_2"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Milieu"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Milieu 2"
                        isCaptain={
                          squad["milieu_2"].player_transfertmarkt_id ===
                          squad["captain"].player_transfertmarkt_id
                        }
                      ></Player>
                      <Player
                        position="milieu_3"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={squad["milieu_3"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Milieu"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Milieu 3"
                        isCaptain={
                          squad["milieu_3"].player_transfertmarkt_id ===
                          squad["captain"].player_transfertmarkt_id
                        }
                      ></Player>
                      <Player
                        position="milieu_4"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={squad["milieu_4"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Milieu"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Milieu 4"
                        isCaptain={
                          squad["milieu_4"].player_transfertmarkt_id ===
                          squad["captain"].player_transfertmarkt_id
                        }
                      ></Player>
                    </Stack>
                    <Stack
                      sx={{ margin: "auto" }}
                      direction="row"
                      justifyContent={"space-evenly"}
                    >
                      <Player
                        position="attaque_1"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={squad["attaque_1"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Attaque"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Attaquant 1"
                        isCaptain={
                          squad["attaque_1"].player_transfertmarkt_id ===
                          squad["captain"].player_transfertmarkt_id
                        }
                      ></Player>
                      <Player
                        position="attaque_2"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={squad["attaque_2"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Attaque"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Attaquant 1"
                        isCaptain={
                          squad["attaque_2"].player_transfertmarkt_id ===
                          squad["captain"].player_transfertmarkt_id
                        }
                      ></Player>
                    </Stack>
                  </div>
                  {!hasSubs && updatedTeamStrength && (
                    <div className="substitutes-section">
                      <div className="substitutes-section-header">
                        Remplançants
                      </div>
                      <Stack
                        sx={{ margin: "auto", textAlign: "center" }}
                        direction="row"
                        justifyContent={"space-evenly"}
                      >
                        <Player
                          position="goalkeeper_sub"
                          setSelectedPlayer={async () => {
                            if (
                              user?.id == homeTeam.created_by &&
                              playerTurn == user?.id
                            )
                              await handleSubstitution("goalkeeper_sub");
                          }}
                          setPosition={handlePositionChange}
                          selectedPlayer={selectedPlayer}
                          squadSkeleton={squad["goalkeeper_sub"]}
                          setHighlight={setHighlight}
                          highlight={highlight}
                          abstractPosition={"Goalkeeper"}
                          handleShowDetails={handleShowDetails}
                          placeholder="Gardien de but"
                          isCaptain={
                            squad["goalkeeper_sub"].player_transfertmarkt_id ===
                            squad["captain"].player_transfertmarkt_id
                          }
                        ></Player>
                        <Player
                          position="defense_sub"
                          setSelectedPlayer={async () => {
                            if (
                              user?.id == homeTeam.created_by &&
                              playerTurn == user?.id
                            )
                              await handleSubstitution("defense_sub");
                          }}
                          setPosition={handlePositionChange}
                          selectedPlayer={selectedPlayer}
                          squadSkeleton={squad["defense_sub"]}
                          setHighlight={setHighlight}
                          highlight={highlight}
                          abstractPosition={"Defense"}
                          handleShowDetails={handleShowDetails}
                          placeholder="Défenseur"
                          isCaptain={
                            squad["defense_sub"].player_transfertmarkt_id ===
                            squad["captain"].player_transfertmarkt_id
                          }
                        ></Player>
                        <Player
                          position="milieu_sub"
                          setSelectedPlayer={async () => {
                            if (
                              user?.id == homeTeam.created_by &&
                              playerTurn == user?.id
                            )
                              await handleSubstitution("milieu_sub");
                          }}
                          setPosition={handlePositionChange}
                          selectedPlayer={selectedPlayer}
                          squadSkeleton={squad["milieu_sub"]}
                          setHighlight={setHighlight}
                          highlight={highlight}
                          abstractPosition={"Milieu"}
                          handleShowDetails={handleShowDetails}
                          placeholder="Milieu"
                          isCaptain={
                            squad["milieu_sub"].player_transfertmarkt_id ===
                            squad["captain"].player_transfertmarkt_id
                          }
                        ></Player>
                        <Player
                          position="attaque_sub"
                          setSelectedPlayer={async () => {
                            if (
                              user?.id == homeTeam.created_by &&
                              playerTurn == user?.id
                            )
                              await handleSubstitution("attaque_sub");
                          }}
                          setPosition={handlePositionChange}
                          selectedPlayer={selectedPlayer}
                          squadSkeleton={squad["attaque_sub"]}
                          setHighlight={setHighlight}
                          highlight={highlight}
                          abstractPosition={"Attaque"}
                          handleShowDetails={handleShowDetails}
                          placeholder="Attaquant"
                          isCaptain={
                            squad["attaque_sub"].player_transfertmarkt_id ===
                            squad["captain"].player_transfertmarkt_id
                          }
                        ></Player>
                      </Stack>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            xs={5}
            sm={5}
            direction="column"
            sx={{ alignSelf: "flex-start" }}
          >
            <div className="view-toggle-selection">
              <ToggleButtonGroup
                color="secondary"
                value={alignment}
                exclusive
                onChange={handleChangeView}
              >
                <ToggleButton value="card">Match</ToggleButton>
                <ToggleButton value="data">Stats Visiteurs</ToggleButton>
                {user?.id == awayTeam?.created_by && (
                  <ToggleButton value="capitain">Avis capitaine</ToggleButton>
                )}
                <ToggleButton value="team">Equipe Visteur</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className="team-name-section-header team-name-section-header__away">
              {awayTeam.team_name}
            </div>
            <Grid
              item
              xs={12}
              sm={12}
              style={{ margin: "auto" }}
              className="pitch-section"
            >
              {alignment == "card" && (
                <div className="timeline-view">
                  <Stack
                    direction="column"
                    alignSelf={"flex-start"}
                    justifyContent={"space-evenly"}
                    alignContent={"center"}
                  >
                    <CustomizedTimeline
                      handlePopoverOpen={handlePopoverOpen}
                      handlePopoverClose={handlePopoverClose}
                      awayPlayedCards={awayPlayedCards}
                      homePlayedCards={homePlayedCards}
                    ></CustomizedTimeline>
                  </Stack>
                </div>
              )}

              {alignment == "data" && (
                <div className="stats-view">
                  <Grid>
                    <StatsBadgeGaugeRound
                      value={updatedTeamStrength?.away.defense ?? 0}
                      category={"Stabilité defensive"}
                    ></StatsBadgeGaugeRound>
                    <StatsBadgeGaugeRound
                      category={"Fluidité de passe"}
                      value={updatedTeamStrength?.away.milieu ?? 0}
                    ></StatsBadgeGaugeRound>
                    <StatsBadgeGaugeRound
                      category={"Probabilité de but"}
                      value={updatedTeamStrength?.away.attack ?? 0}
                    ></StatsBadgeGaugeRound>
                  </Grid>
                  <StatsBadgeNoteSmall
                    category={"Valeur d'équipe"}
                    value={updatedTeamStrength?.away.total_lineup ?? "-"}
                    icon={<PaidIcon fontSize="large"></PaidIcon>}
                  ></StatsBadgeNoteSmall>
                  <StatsBadgeNoteSmall
                    category={"Cartons Jaunes"}
                    value={yellowCardAway ?? 0}
                    icon={
                      <StyleIcon
                        sx={{ color: "yellow" }}
                        color="warning"
                        fontSize="large"
                      ></StyleIcon>
                    }
                  ></StatsBadgeNoteSmall>
                  <StatsBadgeNoteSmall
                    category={"Cartons Rouges"}
                    value={redCardAway ?? 0}
                    icon={
                      <StyleIcon
                        sx={{ color: "red" }}
                        color="warning"
                        fontSize="large"
                      ></StyleIcon>
                    }
                  ></StatsBadgeNoteSmall>
                </div>
              )}
              {alignment == "capitain" && (
                <div className="captain-view">
                  <Player
                    position="Captain"
                    squadSkeleton={awayTeam.squad["captain"]}
                    placeholder={awayTeam.squad["captain"].player_name}
                    isCaptain={true}
                  ></Player>
                  <div className="captain-messages">
                    {messages.map((message) => {
                      return (
                        <Alert className="capitain-voice" severity="info">
                          {message}
                        </Alert>
                      );
                    })}
                  </div>
                </div>
              )}
              {alignment == "team" && (
                <>
                  <div
                    className="pitch"
                    style={{ backgroundImage: "url(pitch.svg)" }}
                  >
                    <div className="substitutes-section-header">Titulaires</div>
                    <Stack
                      sx={{ margin: "auto" }}
                      direction="row"
                      justifyContent={"space-evenly"}
                    >
                      <Player
                        position="goalkeeper_1"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={awayTeam?.squad["goalkeeper_1"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Goalkeeper"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Gardien de but"
                        isCaptain={
                          awayTeam?.squad["goalkeeper_1"]
                            ?.player_transfertmarkt_id ===
                          awayTeam?.squad["captain"]?.player_transfertmarkt_id
                        }
                      ></Player>
                    </Stack>
                    <Stack
                      sx={{ margin: "auto" }}
                      direction="row"
                      justifyContent={"space-evenly"}
                    >
                      <Player
                        position="defense_1"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={awayTeam?.squad["defense_1"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Defense"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Défenseur 1"
                        isCaptain={
                          awayTeam?.squad["defense_1"]
                            ?.player_transfertmarkt_id ===
                          awayTeam?.squad["captain"]?.player_transfertmarkt_id
                        }
                      ></Player>
                      <Player
                        position="defense_2"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={awayTeam?.squad["defense_2"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Defense"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Défenseur 2"
                        isCaptain={
                          awayTeam?.squad["defense_2"]
                            ?.player_transfertmarkt_id ===
                          awayTeam?.squad["captain"]?.player_transfertmarkt_id
                        }
                      ></Player>
                      <Player
                        position="defense_3"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={awayTeam?.squad["defense_3"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Defense"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Défenseur 3"
                        isCaptain={
                          awayTeam?.squad["defense_3"]
                            ?.player_transfertmarkt_id ===
                          awayTeam?.squad["captain"]?.player_transfertmarkt_id
                        }
                      ></Player>
                      <Player
                        position="defense_4"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={awayTeam?.squad["defense_4"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Defense"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Défenseur 4"
                        isCaptain={
                          awayTeam?.squad["defense_4"]
                            ?.player_transfertmarkt_id ===
                          awayTeam?.squad["captain"]?.player_transfertmarkt_id
                        }
                      ></Player>
                    </Stack>
                    <Stack
                      sx={{ margin: "auto" }}
                      direction="row"
                      justifyContent={"space-evenly"}
                    >
                      <Player
                        position="milieu_1"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={awayTeam?.squad["milieu_1"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Milieu"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Milieu 1"
                        isCaptain={
                          awayTeam?.squad["milieu_1"]
                            ?.player_transfertmarkt_id ===
                          awayTeam?.squad["captain"]?.player_transfertmarkt_id
                        }
                      ></Player>
                      <Player
                        position="milieu_2"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={awayTeam?.squad["milieu_2"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Milieu"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Milieu 2"
                        isCaptain={
                          awayTeam?.squad["milieu_2"]
                            ?.player_transfertmarkt_id ===
                          awayTeam?.squad["captain"]?.player_transfertmarkt_id
                        }
                      ></Player>
                      <Player
                        position="milieu_3"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={awayTeam?.squad["milieu_3"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Milieu"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Milieu 3"
                        isCaptain={
                          awayTeam?.squad["milieu_3"]
                            ?.player_transfertmarkt_id ===
                          awayTeam?.squad["captain"]?.player_transfertmarkt_id
                        }
                      ></Player>
                      <Player
                        position="milieu_4"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={awayTeam?.squad["milieu_4"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Milieu"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Milieu 4"
                        isCaptain={
                          awayTeam?.squad["milieu_4"]
                            ?.player_transfertmarkt_id ===
                          awayTeam?.squad["captain"]?.player_transfertmarkt_id
                        }
                      ></Player>
                    </Stack>
                    <Stack
                      sx={{ margin: "auto" }}
                      direction="row"
                      justifyContent={"space-evenly"}
                    >
                      <Player
                        position="attaque_1"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={awayTeam?.squad["attaque_1"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Attaque"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Attaquant 1"
                        isCaptain={
                          awayTeam?.squad["attaque_1"]
                            ?.player_transfertmarkt_id ===
                          awayTeam?.squad["captain"]?.player_transfertmarkt_id
                        }
                      ></Player>
                      <Player
                        position="attaque_2"
                        setSelectedPlayer={setSelectedPlayer}
                        setPosition={handlePositionChange}
                        selectedPlayer={selectedPlayer}
                        squadSkeleton={awayTeam?.squad["attaque_2"]}
                        setHighlight={setHighlight}
                        highlight={highlight}
                        abstractPosition={"Attaque"}
                        handleShowDetails={handleShowDetails}
                        placeholder="Attaquant 1"
                        isCaptain={
                          awayTeam?.squad["attaque_2"]
                            ?.player_transfertmarkt_id ===
                          awayTeam?.squad["captain"]?.player_transfertmarkt_id
                        }
                      ></Player>
                    </Stack>
                  </div>
                  {!hasSubs && updatedTeamStrength && (
                    <div className="substitutes-section">
                      <div className="substitutes-section-header">
                        Remplançants
                      </div>
                      <Stack
                        sx={{ margin: "auto", textAlign: "center" }}
                        direction="row"
                        justifyContent={"space-evenly"}
                      >
                        <Player
                          position="goalkeeper_sub"
                          setSelectedPlayer={async () => {
                            if (
                              user?.id == awayTeam.created_by &&
                              playerTurn == user?.id
                            )
                              await handleSubstitution("goalkeeper_sub");
                          }}
                          setPosition={handlePositionChange}
                          selectedPlayer={selectedPlayer}
                          squadSkeleton={awayTeam?.squad["goalkeeper_sub"]}
                          setHighlight={setHighlight}
                          highlight={highlight}
                          abstractPosition={"Goalkeeper"}
                          handleShowDetails={handleShowDetails}
                          placeholder="Gardien de but"
                          isCaptain={
                            awayTeam?.squad["goalkeeper_sub"]
                              ?.player_transfertmarkt_id ===
                            awayTeam?.squad["captain"]?.player_transfertmarkt_id
                          }
                        ></Player>
                        <Player
                          position="defense_sub"
                          setSelectedPlayer={async () => {
                            if (
                              user?.id == awayTeam.created_by &&
                              playerTurn == user?.id
                            )
                              await handleSubstitution("defense_sub");
                          }}
                          setPosition={handlePositionChange}
                          selectedPlayer={selectedPlayer}
                          squadSkeleton={awayTeam?.squad["defense_sub"]}
                          setHighlight={setHighlight}
                          highlight={highlight}
                          abstractPosition={"Defense"}
                          handleShowDetails={handleShowDetails}
                          placeholder="Défenseur"
                          isCaptain={
                            awayTeam?.squad["defense_sub"]
                              ?.player_transfertmarkt_id ===
                            awayTeam?.squad["captain"]?.player_transfertmarkt_id
                          }
                        ></Player>
                        <Player
                          position="milieu_sub"
                          setSelectedPlayer={async () => {
                            if (
                              user?.id == awayTeam.created_by &&
                              playerTurn == user?.id
                            )
                              await handleSubstitution("milieu_sub");
                          }}
                          setPosition={handlePositionChange}
                          selectedPlayer={selectedPlayer}
                          squadSkeleton={awayTeam?.squad["milieu_sub"]}
                          setHighlight={setHighlight}
                          highlight={highlight}
                          abstractPosition={"Milieu"}
                          handleShowDetails={handleShowDetails}
                          placeholder="Milieu"
                          isCaptain={
                            awayTeam?.squad["milieu_sub"]
                              ?.player_transfertmarkt_id ===
                            awayTeam?.squad["captain"]?.player_transfertmarkt_id
                          }
                        ></Player>
                        <Player
                          position="attaque_sub"
                          setSelectedPlayer={async () => {
                            if (
                              user?.id == awayTeam.created_by &&
                              playerTurn == user?.id
                            )
                              await handleSubstitution("attaque_sub");
                          }}
                          setPosition={handlePositionChange}
                          selectedPlayer={selectedPlayer}
                          squadSkeleton={awayTeam?.squad["attaque_sub"]}
                          setHighlight={setHighlight}
                          highlight={highlight}
                          abstractPosition={"Attaque"}
                          handleShowDetails={handleShowDetails}
                          placeholder="Attaquant"
                          isCaptain={
                            awayTeam?.squad["attaque_sub"]
                              ?.player_transfertmarkt_id ===
                            awayTeam?.squad["captain"]?.player_transfertmarkt_id
                          }
                        ></Player>
                      </Stack>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PlayerDrawer
        showData={showStats}
        setShowData={setShowStats}
        stats={stats}
        selectedPlayer={selectedPlayer}
        switchPlayer={switchPlayer}
        playerIn={playerIn}
        position={position}
        showCaptainSelection={showCaptainSelection}
      ></PlayerDrawer>
      <CardDrawer
        showData={showCardDetail}
        setShowData={setShowCardDetail}
        card={card}
        addCard={addCard}
      ></CardDrawer>
      <Dialog open={showSaveDialog} onClose={cancelSave}>
        <DialogTitle>Sauvegarder équipe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez renseigner un nom à votre équipe.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="team_name"
            label="Nom d'équipe"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleTeamName}
            value={teamName}
          />
        </DialogContent>
        <DialogActions>
          <div className="actions-area-save">
            <Button onClick={cancelSave}>Cancel</Button>
            <Button onClick={saveTeam}>Sauvegarder</Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showSubDialog && user?.id == playerTurn}
        onClose={handleCloseSub}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmer le remplacement?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {squad[subIn]?.player_name} va faire son entrée.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSub}>Disagree</Button>
          <Button onClick={substitutePlayer} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="mouse-over-popover">
          <Typography
            variant="h5"
            className="mouse-over-popover-title"
            sx={{ p: 1 }}
          >
            {card?.card_name}
          </Typography>
          <p
            variant="subtitle"
            className="mouse-over-popover-description"
            sx={{ p: 1 }}
          >
            <b>{card?.category.toUpperCase()}</b>
          </p>
          <Typography
            variant="subtitle"
            className="mouse-over-popover-description"
            sx={{ p: 1 }}
          >
            {card?.card_description}
          </Typography>
          <div className="mouse-over-popover-attribute-container">
            <Typography className="mouse-over-popover-attribute" sx={{ p: 1 }}>
              Défense: <b>{card?.defense}</b>
            </Typography>
            <Typography className="mouse-over-popover-attribute" sx={{ p: 1 }}>
              Milieu: <b>{card?.milieu}</b>
            </Typography>
            <Typography className="mouse-over-popover-attribute" sx={{ p: 1 }}>
              Attaque:<b>{card?.attaques}</b>
            </Typography>
          </div>
        </div>
      </Popover>
    </Container>
  );
}

export default Match;
