import * as React from "react";
import "./EventDisplay.scss";
import { Grid, Card } from "@mui/material";
import { useEffect, useState } from "react";
import StyleIcon from "@mui/icons-material/Style";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";

function EventDisplayYellowCard(props) {
  return (
    <Grid
      style={{
        display: "flex",
        width: "100%",

        paddingRight: "48px",
        position: "fixed",
      }}
      justifyContent={props.homeAway == "home" ? "left" : "right"}
    >
      <Card style={{ width: "120px", marginTop: "30px" }} variant="outlined">
        <StyleIcon
          sx={{ color: "yellow", fontSize: "100px" }}
          color="warning"
        ></StyleIcon>
      </Card>
    </Grid>
  );
}
function EventDisplayRedCard(props) {
  return (
    <Grid
      style={{
        display: "flex",
        width: "100%",

        paddingRight: "48px",
        position: "fixed",
      }}
      justifyContent={props.homeAway == "home" ? "left" : "right"}
    >
      <Card style={{ width: "120px", marginTop: "30px" }} variant="outlined">
        <StyleIcon sx={{ fontSize: "100px" }} color="error"></StyleIcon>
      </Card>
    </Grid>
  );
}
function EventDisplayGoal(props) {
  return (
    <Grid
      style={{
        display: "flex",
        width: "100%",

        paddingRight: "48px",
        position: "fixed",
      }}
      justifyContent={props.homeAway == "home" ? "left" : "right"}
    >
      <Card style={{ width: "120px", marginTop: "30px" }} variant="outlined">
        <SportsSoccerIcon
          sx={{ fontSize: "100px" }}
          color="success"
        ></SportsSoccerIcon>
      </Card>
    </Grid>
  );
}

export { EventDisplayGoal, EventDisplayYellowCard, EventDisplayRedCard };
