import "./App.css";

import { Page } from "./components/Home/Page";
import { useEffect, useState } from "react";
import { FixtureAdmin } from "./components/Home/FixtureAdmin";
import MatchPage from "./components/Home/MatchPage";
import Signup from "./components/SignUp/SignUp";
import Signin from "./components/SignIn/SignIn";
import Logout from "./components/Logout/Logout";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import useToken from "./useToken";
import ForgottenPassword from "./components/ForgottenPassword/ForgottenPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import { Privacy } from "./components/Home/Privacy";

function App() {
  const { token, setToken } = useToken();

  if (token) {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Page />}></Route>
            <Route path="/admin/:fixture_id" element={<FixtureAdmin />}></Route>
            <Route path="/:match_id" element={<MatchPage />}></Route>
            <Route path="/logout" element={<Logout />}></Route>
            <Route path="/privacy" element={<Privacy />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    );
  } else {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Signin setToken={setToken} />}></Route>
            <Route path="/privacy" element={<Privacy />}></Route>
            <Route path="/signup" element={<Signup />}></Route>
            <Route path="/reset" element={<ForgottenPassword />}></Route>
            <Route
              path="/newPassword/:token/:user_id"
              element={<ResetPassword />}
            ></Route>
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
