import "./Logout.scss";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  TextField,
  Link,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { mainUrl } from "../../env";
import useToken from "../../useToken";
function Logout(props) {
  const { token, setToken } = useToken();

  useEffect(() => {
    setToken("");
  }, []);

  return (
    <Container
      maxWidth={false}
      sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", height: "100%" }}
      >
        <Grid
          item
          sx={{ maxWidth: "70rem", width: "100%", backgroundColor: "#fff" }}
        >
          <Grid
            container
            sx={{
              boxShadow: { sm: "0 0 5px #ddd" },
              py: "6rem",
              px: "1rem",
            }}
          >
            <Grid
              item
              container
              justifyContent="space-between"
              rowSpacing={5}
              sx={{
                marginInline: "auto",
              }}
            >
              <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
                <img
                  src="./botola_pro.png"
                  style={{ width: "30%", margin: "auto" }}
                  loading="lazy"
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Stack sx={{ mt: "3rem", textAlign: "center" }}>
                <Link
                  href={"/"}
                  variant="contained"
                  sx={{
                    py: "0.8rem",
                    mt: 2,
                    cursor: "pointer",
                    marginInline: "auto",
                  }}
                >
                  S'identifer
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Logout;
