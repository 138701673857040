import { useState } from "react";

import QueryStatsIcon from "@mui/icons-material/QueryStats";

import { StatsBadgeTitle } from "./StatsBadge";
import {
  Stack,
  Drawer,
  Typography,
  Popover,
  Paper,
  Box,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomizedTimeline from "./MatchTimeline";

function EventsDrawer(props) {
  const [card, setCard] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = async (event, myCard) => {
    setAnchorEl(event.currentTarget);
    await setCard(myCard);
  };
  const open = Boolean(anchorEl);
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <Drawer
      anchor={"right"}
      open={props.showEvents}
      onClose={() => props.setShowEvents(false)}
    >
      <StatsBadgeTitle
        title={"Details du match"}
        icon={<QueryStatsIcon fontSize="large"></QueryStatsIcon>}
      ></StatsBadgeTitle>

      <Stack
        style={{
          width: "700px",

          padding: "12px",
          background: "black",
        }}
        direction={"column"}
      >
        <div className="timeline-view timeline-view__events">
          <Box sx={{ marginBottom: "12px", width: "100%" }}>
            <Stack direction="row" spacing={2}>
              <Alert
                onMouseEnter={() => {}}
                onMouseLeave={() => {}}
                icon={false}
                variant="filled"
                className="alert timeline-titles"
                severity="warning"
              >
                Home
              </Alert>
              <Alert
                onMouseEnter={() => {}}
                onMouseLeave={() => {}}
                icon={false}
                variant="filled"
                className="alert__away timeline-titles"
                severity="warning"
              >
                Away
              </Alert>
            </Stack>
          </Box>
          <CustomizedTimeline
            awayPlayedCards={props.cards_played_away}
            homePlayedCards={props.cards_played_home}
            timeline={props.timeline}
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
          ></CustomizedTimeline>
        </div>
      </Stack>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="mouse-over-popover">
          <Typography
            variant="h5"
            className="mouse-over-popover-title"
            sx={{ p: 1 }}
          >
            {card?.card_name}
          </Typography>
          <p
            variant="subtitle"
            className="mouse-over-popover-description"
            sx={{ p: 1 }}
          >
            <b>{card?.category.toUpperCase()}</b>
          </p>
          <Typography
            variant="subtitle"
            className="mouse-over-popover-description"
            sx={{ p: 1 }}
          >
            {card?.card_description}
          </Typography>
          <div className="mouse-over-popover-attribute-container">
            <Typography className="mouse-over-popover-attribute" sx={{ p: 1 }}>
              Défense: <b>{card?.defense}</b>
            </Typography>
            <Typography className="mouse-over-popover-attribute" sx={{ p: 1 }}>
              Milieu: <b>{card?.milieu}</b>
            </Typography>
            <Typography className="mouse-over-popover-attribute" sx={{ p: 1 }}>
              Attaque:<b>{card?.attaques}</b>
            </Typography>
          </div>
        </div>
      </Popover>
    </Drawer>
  );
}
export default EventsDrawer;
