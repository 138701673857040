import "./Page.scss";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";

import Match from "./Match";
import { SnackbarProvider } from "notistack";

function MatchPage(props) {
  let navigate = useNavigate();
  const toHome = () => {
    navigate("/");
  };
  return (
    <>
      <Stack>
        <AppBar position="static">
          <Toolbar className="header-menu">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toHome}
            >
              <HomeIcon />
            </IconButton>
            <img
              style={{ width: "370px", verticalAlign: "middle" }}
              src="./logo.png"
            />

            <Button onClick={() => navigate("/logout")} color="inherit">
              Déconnexion
            </Button>
          </Toolbar>
        </AppBar>
      </Stack>
      <Stack>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          preventDuplicate
          maxSnack={3}
        >
          <Match></Match>
        </SnackbarProvider>
      </Stack>
    </>
  );
}

export default MatchPage;
