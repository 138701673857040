import "./Page.scss";
import { useEffect, useState } from "react";
import {
  Stack,
  ListItem,
  MenuItem,
  Select,
  FormControl,
  Box,
  InputLabel,
  Container,
} from "@mui/material";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import Home from "./Home";
import Footer from "../Footer/Footer";

function Page(props) {
  let navigate = useNavigate();
  const toHome = () => {
    navigate("/");
  };
  return (
    <>
      <Stack>
        <AppBar position="static">
          <Toolbar className="header-menu">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toHome}
            >
              <HomeIcon />
            </IconButton>
            <img
              style={{ width: "370px", verticalAlign: "middle" }}
              src="./logo.png"
            />

            <Button onClick={() => navigate("/logout")} color="inherit">
              Déconnexion
            </Button>
          </Toolbar>
        </AppBar>
      </Stack>
      <Stack>
        <Home></Home>
        <Footer></Footer>
      </Stack>
    </>
  );
}

export { Page };
