import { Link, Box, Grid, Container, Typography } from "@mui/material";

export default function Footer() {
  return (
    <footer>
      <Box
        px={{ xs: 3, sm: 3 }}
        py={{ xs: 5, sm: 3 }}
        bgcolor="text.primary"
        color="white"
      >
        <Container maxWidth="lg">
          <Grid justifyContent={"center"} container spacing={5}>
            <Grid item xs={12} sm={3}>
              <Box marginBottom={"12px"} borderBottom={1}>
                Aide
              </Box>
              <Box>
                <Link underline="none" href="/" color="inherit">
                  <Typography component={"h3"}>Accueil</Typography>
                </Link>
              </Box>
              <Box>
                <Link underline="none" href="/" color="inherit">
                  <Typography component={"h3"}> Comment jouer?</Typography>
                </Link>
              </Box>
              <Box>
                <Link href="/" underline="none" color="inherit">
                  <Typography component={"h3"}>Contact</Typography>
                </Link>
              </Box>
              <Box>
                <Link href="/privacy" underline="none" color="inherit">
                  <Typography component={"h3"}>Privacy</Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Box textAlign="center" pt={{ xs: 5, sm: 3 }} pb={{ xs: 5, sm: 0 }}>
            THE LEAGUE &reg; {new Date().getFullYear()}
          </Box>
        </Container>
      </Box>
    </footer>
  );
}
