import { useState } from "react";
import StadiumIcon from "@mui/icons-material/Stadium";
import PersonIcon from "@mui/icons-material/Person";
import SportsSoccerTwoToneIcon from "@mui/icons-material/SportsSoccerTwoTone";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import AdjustIcon from "@mui/icons-material/Adjust";
import StyleIcon from "@mui/icons-material/Style";
import BrowserNotSupportedIcon from "@mui/icons-material/BrowserNotSupported";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import {
  StatsBadgeTitle,
  StatsBadgeNote,
  StatsBadgeRatio,
  StatsBadgeName,
} from "./StatsBadge";
import {
  Stack,
  Drawer,
  List,
  ListItem,
  Typography,
  Popover,
  Box,
  CircularProgress,
  Divider,
  Button,
} from "@mui/material";

function PlayerDrawer(props) {
  return (
    <Drawer
      anchor={"right"}
      open={props.showData}
      onClose={() => props.setShowData(false)}
    >
      <StatsBadgeTitle
        title={props.stats?.player_name}
        icon={<PersonIcon fontSize="large"></PersonIcon>}
      ></StatsBadgeTitle>
      <StatsBadgeTitle
        title={"Player statistics"}
        icon={<QueryStatsIcon fontSize="large"></QueryStatsIcon>}
      ></StatsBadgeTitle>

      <Stack
        style={{
          width: "700px",
          height: "100%",
          padding: "12px",
          backgroundColor: "black",
        }}
        direction={"column"}
      >
        {props.playerIn?.player_position != "Goalkeeper" && (
          <div className="statistics-section">
            <StatsBadgeNote
              category={"Matchs"}
              value={props.stats?.player_appearances}
              icon={<StadiumIcon fontSize="large"></StadiumIcon>}
            ></StatsBadgeNote>

            <StatsBadgeNote
              category={"Assists"}
              value={props.stats?.player_assists}
              icon={
                <SportsSoccerTwoToneIcon fontSize="large"></SportsSoccerTwoToneIcon>
              }
            ></StatsBadgeNote>

            <StatsBadgeNote
              category={"Buts"}
              value={props.stats?.player_goals}
              icon={<SportsSoccerIcon fontSize="large"></SportsSoccerIcon>}
            ></StatsBadgeNote>

            <StatsBadgeNote
              category={"Buts sur penalty"}
              value={props.stats?.player_penalty_kick}
              icon={<AdjustIcon fontSize="large"></AdjustIcon>}
            ></StatsBadgeNote>

            <StatsBadgeNote
              category={"Minutes jouées"}
              value={props.stats?.player_minutes_played}
              icon={<AvTimerIcon fontSize="large"></AvTimerIcon>}
            ></StatsBadgeNote>

            <StatsBadgeNote
              category={"Buts par minute joué"}
              value={props.stats?.player_minutes_per_goal}
              icon={<AlarmOnIcon fontSize="large"></AlarmOnIcon>}
            ></StatsBadgeNote>

            <div className="ratio-section">
              <StatsBadgeRatio
                category={"Ratio Assists"}
                value={parseInt(props.stats?.player_assists_ratio)}
              ></StatsBadgeRatio>
              <StatsBadgeRatio
                category={"Ratio Buts"}
                value={parseInt(props.stats?.player_goals_ratio)}
              ></StatsBadgeRatio>
            </div>
          </div>
        )}
        {props.playerIn?.player_position == "Goalkeeper" && (
          <div className="goalkeeper-section">
            <StatsBadgeNote
              category={"Matchs sans buts"}
              value={parseInt(props.stats?.player_clean_sheets)}
              icon={
                <BrowserNotSupportedIcon fontSize="large"></BrowserNotSupportedIcon>
              }
            ></StatsBadgeNote>

            <StatsBadgeNote
              category={"Buts encaissés"}
              value={parseInt(props.stats?.player_goals_conceded)}
              icon={<OpenInBrowserIcon fontSize="large"></OpenInBrowserIcon>}
            ></StatsBadgeNote>

            <StatsBadgeNote
              category={"Minutes par Buts encaissés"}
              value={parseInt(props.stats?.player_minutes_per_goal_against)}
              icon={<AvTimerIcon fontSize="large"></AvTimerIcon>}
            ></StatsBadgeNote>
            <StatsBadgeNote
              category={"Total penalty"}
              value={parseInt(props.stats?.player_total_penalty_kicks)}
              icon={<SportsSoccerIcon fontSize="large"></SportsSoccerIcon>}
            ></StatsBadgeNote>
            <StatsBadgeRatio
              category={"Matchs sans buts"}
              value={parseInt(props.stats?.player_clean_sheets_ratio)}
              icon={<AvTimerIcon fontSize="large"></AvTimerIcon>}
            ></StatsBadgeRatio>
            <StatsBadgeRatio
              category={"Ratio penalty captés"}
              value={parseInt(props.stats?.player_penalty_save_ratio)}
              icon={<AvTimerIcon fontSize="large"></AvTimerIcon>}
            ></StatsBadgeRatio>
          </div>
        )}
        <div className="discipline-section">
          <StatsBadgeNote
            category={"Cartons jaunes"}
            value={props.stats?.player_yellow_cards}
            icon={
              <StyleIcon
                sx={{ color: "yellow" }}
                color="warning"
                fontSize="large"
              ></StyleIcon>
            }
          ></StatsBadgeNote>

          <StatsBadgeNote
            category={"Suspension carton jaune"}
            value={props.stats?.player_yellow_cards_suspension}
            icon={<StyleIcon color="warning" fontSize="large"></StyleIcon>}
          ></StatsBadgeNote>

          <StatsBadgeNote
            category={"Cartons rouges"}
            value={props.stats?.player_red_cards}
            icon={<StyleIcon color="error" fontSize="large"></StyleIcon>}
          ></StatsBadgeNote>

          <StatsBadgeNote
            category={" Cartons par matchs"}
            value={props.stats?.player_cards_per_match}
            icon={<StyleIcon color="info" fontSize="large"></StyleIcon>}
          ></StatsBadgeNote>
        </div>
        <Stack>
          <div className="actions-area">
            {props.selectedPlayer?.player &&
              props.selectedPlayer?.position.includes(
                props?.position.toLowerCase()
              ) && (
                <Button
                  onClick={() =>
                    props.switchPlayer({
                      position: props.selectedPlayer?.position,
                      player: props.playerIn,
                    })
                  }
                >
                  Ajouter a l'equipe
                </Button>
              )}
            {props.selectedPlayer?.player?.player_name &&
              props.showCaptainSelection && (
                <Button
                  className="captain-action"
                  onClick={() =>
                    props.switchPlayer({
                      position: "capitain",
                      captain: props.selectedPlayer,
                    })
                  }
                >
                  Capitaine d'équipe
                </Button>
              )}
          </div>
        </Stack>
      </Stack>
    </Drawer>
  );
}
export default PlayerDrawer;
