import "./Fixtures.scss";
import {
  Stack,
  List,
  MenuItem,
  Select,
  FormControl,
  Box,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { FixturePresentation } from "./FixturePresentation";
import { mainUrl } from "../../env";
import useToken from "../../useToken";

function Fixtures(props) {
  const [fixtures, setFixtures] = useState([]);
  const [fixtureIndex, setFixtureIndex] = useState([]);
  const { token, setToken } = useToken();

  useEffect(() => {
    axios.defaults.headers.common["x-auth-token"] = token;
  }, [token]);

  const handleChange = (e) => {
    setFixtureIndex(e.target.value);
    axios
      .get(
        mainUrl +
          "/api/fixtures/getfixturesByGameDay?game_day=" +
          e.target.value,
        {}
      )
      .then((res) => {
        const data = res.data.fixtures;
        setFixtures(data);
      });
  };
  useEffect(() => {
    axios.get(mainUrl + "/api/fixtures/getLatestFixtures", {}).then((res) => {
      const data = res.data.fixtures;
      setFixtures(data);
    });
  }, []);

  return (
    <>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={fixtureIndex}
            onChange={handleChange}
          >
            {Array.from(Array(30), (e, i) => {
              return <MenuItem value={i + 1}>Journée #{i + 1}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Box>
      <List>
        {fixtures.map((fixture) => {
          return (
            <FixturePresentation
              id={fixture.id}
              homeTeam={fixture.home_team}
              awayTeam={fixture.away_team}
              homeGoals={fixture.home_goals}
              awayGoals={fixture.away_goals}
              homeTeamShield={fixture.home_team + "_shield.png"}
              awayTeamShield={fixture.away_team + "_shield.png"}
            ></FixturePresentation>
          );
        })}
      </List>
    </>
  );
}

export default Fixtures;
