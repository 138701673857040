import "./FixtureAdmin.scss";
import {
  Stack,
  List,
  MenuItem,
  Select,
  FormControl,
  Box,
  Button,
  InputLabel,
  Container,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { FixturePresentation } from "./FixturePresentation";
import { mainUrl } from "../../env";
import { Routes, Route, useParams } from "react-router-dom";
import SelectMultiple from "react-select";
import useToken from "../../useToken";

function FixtureAdmin(props) {
  const [isAdmin, setIsAdmin] = useState(null);
  const [fixtureIndex, setFixtureIndex] = useState(null);
  const [fixture, setFixture] = useState({});
  const [fixtureResults, setFixtureResults] = useState({});
  const [homeTeam, setHomeTeam] = useState([]);
  const [awayTeam, setAwayTeam] = useState([]);
  const { token, setToken } = useToken();
  let params = useParams();

  useEffect(() => {
    axios.defaults.headers.common["x-auth-token"] = token;
  }, [token]);

  const submitFixtureResults = async () => {
    await axios
      .post(mainUrl + "/api/fixtures/addFixtureResults", {
        fixture_id: fixture.id,
        fixture: fixtureResults,
      })
      .then((res) => {
        console.log(res.data);
      });
  };
  const handleChange = async (e, key) => {
    let tempFixture = fixtureResults;
    tempFixture[key] = e.target.value;
    await setFixtureResults(tempFixture);
  };
  const handleListChange = async (options, key) => {
    let tempFixture = fixtureResults;
    tempFixture[key] = options;
    await setFixtureResults(tempFixture);
  };
  useEffect(() => {
    setFixtureIndex(params.fixture_id);
    axios.get(mainUrl + "/api/users/me", {}).then((res) => {
      const data = res.data.user;
      setIsAdmin(data.isAdmin);
    });
    axios
      .get(mainUrl + "/api/fixtures/getfixtureById?id=" + params.fixture_id, {})
      .then((res) => {
        const data = res.data.fixture;
        setFixture(data);
        console.log(data);
        axios
          .get(
            mainUrl +
              "/api/players/getSquad?id=" +
              res.data.fixture.home_team_transfermarkt_id,
            {}
          )
          .then((res) => {
            const data = res.data.players;
            let options = data.map((datum) => {
              return {
                value: datum.player_transfertmarkt_id,
                label: datum.player_name,
              };
            });
            setHomeTeam(options);
          });
        axios
          .get(
            mainUrl +
              "/api/players/getSquad?id=" +
              res.data.fixture.away_team_transfermarkt_id,
            {}
          )
          .then((res) => {
            const data = res.data.players;
            let options = data.map((datum) => {
              return {
                value: datum.player_transfertmarkt_id,
                label: datum.player_name,
              };
            });

            setAwayTeam(options);
          });
      });
  }, []);
  if (isAdmin)
    return (
      <Container>
        <Typography variant="h1" component="h2">
          Admin area
        </Typography>
        <Stack className="admin-section">
          <Stack direction={"column"}>
            <div className="admin-section-wrapper">
              <div className="admin-section-header">
                <Typography variant="h5">{fixture.home_team} Goals</Typography>
              </div>
              <div className="admin-section-action">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      handleChange(e, "home_goals");
                    }}
                    value={fixtureResults["home_goals"]}
                  >
                    {Array.from(Array(30), (e, i) => {
                      if (i == 29) {
                        i = 0;
                        return;
                      }
                      if (i == 29) {
                        i = 0;
                        return;
                      }
                      return <MenuItem value={i}>{i}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="admin-section-wrapper">
              <div className="admin-section-header">
                <Typography variant="h5">
                  {fixture.home_team} Offsides
                </Typography>
              </div>
              <div className="admin-section-action">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      handleChange(e, "home_offside");
                    }}
                    value={fixtureResults["home_offside"]}
                  >
                    {Array.from(Array(30), (e, i) => {
                      if (i == 29) {
                        i = 0;
                        return;
                      }
                      if (i == 29) {
                        i = 0;
                        return;
                      }
                      return <MenuItem value={i}>{i}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="admin-section-wrapper">
              <div className="admin-section-header">
                <Typography variant="h5">
                  {fixture.home_team} possession
                </Typography>
              </div>
              <div className="admin-section-action">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      handleChange(e, "home_possession");
                    }}
                    value={fixtureResults["home_possession"]}
                  >
                    {Array.from(Array(100), (e, i) => {
                      if (i == 99) {
                        i = 0;
                        return;
                      }
                      return <MenuItem value={i}>{i}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="admin-section-wrapper">
              <div className="admin-section-header">
                <Typography variant="h5">
                  {fixture.home_team} scorers
                </Typography>
              </div>
              <div className="admin-section-action">
                <FormControl fullWidth>
                  <SelectMultiple
                    defaultValue={[]}
                    isMulti
                    name="awaySquad"
                    options={homeTeam}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      handleListChange(selectedOption, "home_scrorers");
                    }}
                  />
                </FormControl>
              </div>
            </div>

            <div className="admin-section-wrapper">
              <div className="admin-section-header">
                <Typography variant="h5">{fixture.home_team} Booked</Typography>
              </div>
              <div className="admin-section-action">
                <FormControl fullWidth>
                  <SelectMultiple
                    defaultValue={[]}
                    isMulti
                    name="awaySquad"
                    options={homeTeam}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      handleListChange(selectedOption, "home_booked");
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div className="admin-section-wrapper">
              <div className="admin-section-header">
                <Typography variant="h5">
                  {fixture.home_team} sent off
                </Typography>
              </div>
              <div className="admin-section-action">
                <FormControl fullWidth>
                  <SelectMultiple
                    defaultValue={[]}
                    isMulti
                    name="awaySquad"
                    options={homeTeam}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      handleListChange(selectedOption, "home_sentoff");
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div className="admin-section-wrapper">
              <div className="admin-section-header">
                <Typography variant="h5">
                  {fixture.home_team} Foulers
                </Typography>
              </div>
              <div className="admin-section-action">
                <FormControl fullWidth>
                  <SelectMultiple
                    defaultValue={[]}
                    isMulti
                    name="awaySquad"
                    options={homeTeam}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      handleListChange(selectedOption, "home_foulers");
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </Stack>
          <Stack direction={"column"}>
            <div className="admin-section-wrapper">
              <div className="admin-section-header">
                <Typography variant="h5">{fixture.away_team} Goals</Typography>
              </div>
              <div className="admin-section-action">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      handleChange(e, "away_goals");
                    }}
                    value={fixtureResults["away_goals"]}
                  >
                    {Array.from(Array(30), (e, i) => {
                      if (i == 29) {
                        i = 0;
                        return;
                      }
                      return <MenuItem value={i}>{i}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="admin-section-wrapper">
              <div className="admin-section-header">
                <Typography variant="h5">
                  {fixture.away_team} Offsides
                </Typography>
              </div>
              <div className="admin-section-action">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      handleChange(e, "away_offside");
                    }}
                    value={fixtureResults["away_offside"]}
                  >
                    {Array.from(Array(30), (e, i) => {
                      if (i == 29) {
                        i = 0;
                        return;
                      }
                      return <MenuItem value={i}>{i}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="admin-section-wrapper">
              <div className="admin-section-header">
                <Typography variant="h5">
                  {fixture.away_team} Possession
                </Typography>
              </div>
              <div className="admin-section-action">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      handleChange(e, "away_possession");
                    }}
                    value={fixtureResults["away_possession"]}
                  >
                    {Array.from(Array(100), (e, i) => {
                      if (i == 99) {
                        i = 0;
                        return;
                      }
                      return <MenuItem value={i}>{i}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="admin-section-wrapper">
              <div className="admin-section-header">
                <Typography variant="h5">
                  {fixture.away_team} Scorers
                </Typography>
              </div>
              <div className="admin-section-action">
                <FormControl fullWidth>
                  <SelectMultiple
                    defaultValue={[]}
                    isMulti
                    name="awaySquad"
                    options={awayTeam}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      handleListChange(selectedOption, "away_scrorers");
                    }}
                  />
                </FormControl>
              </div>
            </div>

            <div className="admin-section-wrapper">
              <div className="admin-section-header">
                <Typography variant="h5">{fixture.away_team} Booked</Typography>
              </div>
              <div className="admin-section-action">
                <FormControl fullWidth>
                  <SelectMultiple
                    defaultValue={[]}
                    isMulti
                    name="awaySquad"
                    options={awayTeam}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      handleListChange(selectedOption, "away_booked");
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div className="admin-section-wrapper">
              <div className="admin-section-header">
                <Typography variant="h5">
                  {fixture.away_team} Sent offs
                </Typography>
              </div>
              <div className="admin-section-action">
                <FormControl fullWidth>
                  <SelectMultiple
                    defaultValue={[]}
                    isMulti
                    name="awaySquad"
                    options={awayTeam}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOption) => {
                      handleListChange(selectedOption, "away_sentoff");
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div className="admin-section-wrapper">
              <div className="admin-section-header">
                <Typography variant="h5">
                  {fixture.away_team} Foulers
                </Typography>
              </div>
              <div className="admin-section-action">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <SelectMultiple
                    defaultValue={[]}
                    isMulti
                    name="awaySquad"
                    options={awayTeam}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOption) => {
                      handleListChange(selectedOption, "away_foulers");
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </Stack>
        </Stack>
        <Stack>
          <Button
            className="fixture-results-action"
            onClick={() => submitFixtureResults()}
          >
            Sauvegarder
          </Button>
        </Stack>
      </Container>
    );
  return;
}

export { FixtureAdmin };
