/* eslint-disable react-hooks/exhaustive-deps */
import "./Home.scss";
import axios from "axios";
import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  Input,
  Link,
  Card,
  Button,
  FormControl,
  Divider,
  Chip,
  List,
  MenuItem,
  Rating,
  ListItem,
  Select,
  Autocomplete,
  TextField,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Player from "./Player";
import { useEffect, useState } from "react";
import { mainUrl } from "../../env";
import PlayerDrawer from "./PlayerDrawer";
import CardDrawer from "./CardDrawer";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Fixtures from "./Fixtures";
import useToken from "../../useToken";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { StatsBadgeNoteSmall } from "./StatsBadge";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import SensorWindowIcon from "@mui/icons-material/SensorWindow";
import { useNavigate } from "react-router-dom";
import Historique from "./Historique";

const squadSkeleton = {
  goalkeeper_1: {},
  defense_1: {},
  defense_2: {},
  defense_3: {},
  defense_4: {},
  milieu_1: {},
  milieu_2: {},
  milieu_3: {},
  milieu_4: {},
  attaque_1: {},
  attaque_2: {},
  goalkeeper_sub: {},
  defense_sub: {},
  milieu_sub: {},
  attaque_sub: {},
  captain: {},
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Home() {
  let navigate = useNavigate();
  const [position, setPosition] = useState("Goalkeeper");
  const [userCards, setUserCards] = useState([]);
  const [user, setUser] = useState("");
  const [players, setPlayers] = useState([]);
  const [cards, setCards] = useState([]);
  const [card, setCard] = useState(null);
  const [showStats, setShowStats] = useState(false);
  const [stats, setStats] = useState(null);
  const [squad, setSquad] = useState(squadSkeleton);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [playerIn, setPlayerIn] = useState(null);
  const [highlight, setHighlight] = useState("");
  const [teamName, setTeamName] = useState("");
  const [budget, setBudget] = useState("");
  const [deckBudget, setDeckBudget] = useState("");
  const [score, setScore] = useState("");
  const [showCaptainSelection, setShowCaptainSelection] = useState(false);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [value, setValue] = useState(0);
  const [showCardDetail, setShowCardDetail] = useState(false);
  const { token, setToken } = useToken();
  const [view, setView] = useState("module");
  const [games, setGames] = useState([]);
  const [leagueRankings, setLeagueRankings] = useState([]);
  const [league, setLeague] = useState("");
  const [leagues, setLeagues] = useState([]);
  const [validSquad, setValidSquad] = useState(false);
  const [myRankings, setMyRankings] = useState(null);

  const shapeStyles = {
    backgroundColor: "green",
    width: 10,
    height: 10,
    display: "block",
  };
  const shapeCircleStyles = { borderRadius: "50%" };
  const circle = (
    <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }} />
  );
  useEffect(() => {
    axios.defaults.headers.common["x-auth-token"] = token;
    axios.get(mainUrl + "/api/users/me", {}).then((res) => {
      if (res.status == 200) {
        const user = res.data.user;
        setUser(user);
        setUserCards(user.cards_deck);
      }
    });
  }, [token]);

  const handleChangeView = (event, nextView) => {
    setView(nextView);
  };
  const addCard = (strategy) => {
    alert(userCards?.reduce((partialSum, a) => partialSum + a.price, 0));
    if (userCards?.filter((c) => strategy.id === c.id)?.length > 0) {
      alert("You cannot have the same strategy twice");
      return;
    } else if (userCards?.length >= 9) {
      alert("You already have 9 cards");
      return;
    } else if (
      userCards?.reduce((partialSum, a) => partialSum + a.price, 0) +
        strategy.price >
      600
    ) {
      alert("Budget dépassé");
    } else {
      let tempDeck = userCards ?? [];
      tempDeck.push(strategy);
      setUserCards(tempDeck);
      setShowCardDetail(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleJoin = async (game, gameId) => {
    if (user.id == game.home_team || user.id == game.away_team) {
      navigate("/" + gameId);
      return;
    }
    await axios
      .post(mainUrl + "/api/games/join", {
        game_id: gameId,
      })
      .then((res) => {
        if (res?.data?.id) navigate("/" + res.data.id);
      });
  };

  const handleCreate = async () => {
    await axios.get(mainUrl + "/api/games/createGame", {}).then((res) => {
      if (res?.data?.gameId) navigate("/" + res.data.gameId);
    });
  };

  const handleExpressPlay = async () => {
    await axios.get(mainUrl + "/api/games/expressPlay", {}).then((res) => {
      if (res?.data?.availableGame) navigate("/" + res.data.availableGame);
    });
  };

  const handleDeleteCard = (cardToBeremoved) => {
    setUserCards(userCards?.filter((Card) => cardToBeremoved.id !== Card.id));
  };

  const getBudget = () => {
    let team_value = 0;

    for (const key in squad) {
      if (key != "captain")
        team_value = team_value + convertToMad(squad[key].market_value ?? 0);
    }
    console.log(team_value);
    return 7000 - team_value;
  };

  const getTeamStrenght = () => {
    let defense_value = 0;
    let milieu_value = 0;
    let attack_value = 0;
    for (const key in squad) {
      if (
        (key.includes("defense") || key.includes("goalkeeper")) &&
        !key.includes("sub") &&
        !key.includes("captain")
      )
        defense_value =
          defense_value + convertToMad(squad[key].market_value ?? 0);
      if (
        key.includes("milieu") &&
        !key.includes("sub") &&
        !key.includes("captain")
      )
        milieu_value =
          milieu_value + convertToMad(squad[key].market_value ?? 0);
      if (
        key.includes("attaque") &&
        !key.includes("sub") &&
        !key.includes("captain")
      )
        attack_value =
          attack_value + convertToMad(squad[key].market_value ?? 0);
    }

    let total_lineup = defense_value + milieu_value + attack_value;
    let points_defense = Math.round((defense_value * 100) / total_lineup);
    let points_milieu = Math.round((milieu_value * 100) / total_lineup);

    return {
      total_lineup: total_lineup,
      defense: points_defense,
      milieu: points_milieu,
      attack: 100 - (points_defense + points_milieu),
    };
  };

  const canBuyPlayer = (player, position) => {
    let team_value = 0;
    for (const key in squad) {
      if (position != key)
        team_value = team_value + convertToMad(squad[key].market_value ?? 0);
    }

    return team_value + convertToMad(player.market_value) <= 7000;
  };
  const convertToMad = (market_value) => {
    return parseFloat(market_value) / 1000;
  };

  const convertToStars = (market_value) => {
    return parseFloat(market_value * 5) / 2000;
  };

  const handleTeamName = (e) => {
    setTeamName(e.target.value);
  };

  const validateSquad = async () => {
    for (var key in squad) {
      if (!squad[key].player_transfertmarkt_id) {
        setValidSquad(false);
        return;
      }
    }
    console.log(!userCards);
    if (!userCards || userCards?.length < 4) {
      setValidSquad(false);
      return;
    }
    setValidSquad(true);
  };

  const switchPlayer = async (data) => {
    if (data.captain) {
      let tempSquad = squad;
      tempSquad["captain"] = data.captain.player;
      setSquad(tempSquad);
      setShowStats(false);
      return;
    }

    if (
      !playerAlreadySelected(squad, data.player) &&
      canBuyPlayer(data.player, data.position)
    ) {
      let tempSquad = squad;
      tempSquad[data.position] = data.player;

      setSelectedPlayer(data.player);
      setSquad(tempSquad);
      setBudget(getBudget());
    } else {
      alert("player already selected or budget dépensé");
    }
    setShowStats(false);
  };

  const playerAlreadySelected = (squad, player) => {
    for (let key in squad) {
      if (squad[key].player_name === player?.player_name) {
        return true;
      }
    }
    return false;
  };

  const cancelSelection = async () => {
    setSquad(squadSkeleton);
    setBudget(7000);
  };
  const handleSaveTeam = async () => {
    if (teamName) {
      await saveTeam();
    } else {
      setShowSaveDialog(true);
    }
  };
  const cancelSave = () => {
    setTeamName("");
    setShowSaveDialog(false);
  };

  const saveTeam = async () => {
    await axios
      .post(mainUrl + "/api/fantasy/setSquad", {
        squad: squad,
        team_name: teamName,
      })
      .then((res) => {
        console.log(res.data);
      });
    await axios
      .post(mainUrl + "/api/cards/setCardsDeck", {
        cards_deck: userCards,
      })
      .then((res) => {
        console.log(res.data);
      });
    setShowSaveDialog(false);
  };

  const handlePositionChange = async (value) => {
    if (!value) return;
    setPosition(value);
    axios
      .get(mainUrl + "/api/players/getPlayersByPosition?position=" + value, {})
      .then((res) => {
        const players = res.data.players;
        setPlayers(players);
      });
  };
  const handleShowDetails = async (player, isIn = false) => {
    if (!player) return;

    setPlayerIn(player);

    if (isIn) {
      setShowCaptainSelection(player);
    }
    await axios
      .get(mainUrl + "/api/stats?id=" + player.player_transfertmarkt_id, {})
      .then(async (res) => {
        const stat = res.data.playerStatistics;
        setStats(stat);
      });
    setShowStats(true);
  };
  const handleShowCardsDetails = async (card) => {
    setCard(card);
    setShowCardDetail(true);
  };
  useEffect(() => {
    axios.get(mainUrl + "/api/fantasy/getSquad", {}).then(async (res) => {
      if (res.data.squad) {
        setSquad(res.data.squad);
      }
      if (res.data.team_name) {
        setTeamName(res.data.team_name);
        setScore(res.data.score);
        setBudget(res.data.budget);
        setDeckBudget(res.data.cards_deck_budget);
      }
    });
    axios
      .get(
        mainUrl + "/api/players/getPlayersByPosition?position=" + position,
        {}
      )
      .then((res) => {
        const players = res.data.players;
        setPlayers(players);
      });

    axios.get(mainUrl + "/api/cards/getCards", {}).then(async (res) => {
      const cards = res.data.cards;
      setCards(cards);
    });
    axios
      .get(mainUrl + "/api/games/getAvailableGames", {})
      .then(async (res) => {
        const availableGames = res.data.availableGames;

        setGames(availableGames);
      });
    axios.get(mainUrl + "/api/fantasy/getSquad", {}).then(async (res) => {
      if (res.data.squad) {
        setSquad(res.data.squad);
      }
      if (res.data.team_name) {
        setTeamName(res.data.team_name);
        setScore(res.data.score);
        setBudget(res.data.budget);
      }
    });

    axios.get(mainUrl + "/api/leagues/getLeagues", {}).then((res) => {
      const data = res.data.leagues;
      setLeagues(data);
    });
  }, []);

  useEffect(() => {
    validateSquad();
  }, [validateSquad, squad, cards]);

  const handleLiguesChange = async (e) => {
    await axios
      .get(
        mainUrl +
          "/api/leagues/getLeagueRanking?league_id=" +
          e.target.value +
          "&user_id=" +
          user.id,
        {}
      )
      .then((res) => {
        setLeague(e.target.value);
        console.log(res);
        const data = res.data.ranks.Fantasyteams;
        setLeagueRankings(data);
        setMyRankings(res.data.rank);
      });
  };
  const handleJoinLigues = async () => {
    console.log(league);
    if (league)
      await axios
        .post(mainUrl + "/api/leagues/join", { league_id: league })
        .then((res) => {});
  };
  return (
    <Container
      sx={{
        maxWidth: "100% !important",
      }}
      className="home-container"
    >
      <Stack>
        <div className="cover">
          <div className="botola-pro-container">
            <div className="botola-pro"></div>
          </div>
        </div>
      </Stack>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", height: "100%" }}
      >
        <Grid item sx={{ width: "100%", backgroundColor: "#fff" }}>
          <Grid container>
            <Grid
              item
              container
              justifyContent="space-between"
              StackSpacing={5}
              sx={{
                marginInline: "auto",
              }}
            >
              <Grid
                item
                xs={12}
                sm={8}
                lg={7}
                style={{ margin: "auto", width: "100%", height: "100%" }}
                className="pitch-section"
              >
                <div
                  className="pitch"
                  style={{ backgroundImage: "url(pitch.svg)" }}
                >
                  <div className="substitutes-section-header">Titulaires</div>{" "}
                  <Stack
                    sx={{ margin: "auto" }}
                    direction="row"
                    justifyContent={"space-evenly"}
                  >
                    <Player
                      position="goalkeeper_1"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["goalkeeper_1"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Goalkeeper"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Gardien de but"
                      isCaptain={
                        squad["goalkeeper_1"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                  </Stack>
                  <Stack
                    sx={{ margin: "auto" }}
                    direction="row"
                    justifyContent={"space-evenly"}
                  >
                    <Player
                      position="defense_1"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["defense_1"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Defense"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Défenseur 1"
                      isCaptain={
                        squad["defense_1"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                    <Player
                      position="defense_2"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["defense_2"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Defense"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Défenseur 2"
                      isCaptain={
                        squad["defense_2"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                    <Player
                      position="defense_3"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["defense_3"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Defense"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Défenseur 3"
                      isCaptain={
                        squad["defense_3"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                    <Player
                      position="defense_4"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["defense_4"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Defense"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Défenseur 4"
                      isCaptain={
                        squad["defense_4"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                  </Stack>
                  <Stack
                    sx={{ margin: "auto" }}
                    direction="row"
                    justifyContent={"space-evenly"}
                  >
                    <Player
                      position="milieu_1"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["milieu_1"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Milieu"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Milieu 1"
                      isCaptain={
                        squad["milieu_1"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                    <Player
                      position="milieu_2"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["milieu_2"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Milieu"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Milieu 2"
                      isCaptain={
                        squad["milieu_2"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                    <Player
                      position="milieu_3"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["milieu_3"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Milieu"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Milieu 3"
                      isCaptain={
                        squad["milieu_3"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                    <Player
                      position="milieu_4"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["milieu_4"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Milieu"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Milieu 4"
                      isCaptain={
                        squad["milieu_4"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                  </Stack>
                  <Stack
                    sx={{ margin: "auto" }}
                    direction="row"
                    justifyContent={"space-evenly"}
                  >
                    <Player
                      position="attaque_1"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["attaque_1"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Attaque"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Attaquant 1"
                      isCaptain={
                        squad["attaque_1"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                    <Player
                      position="attaque_2"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["attaque_2"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Attaque"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Attaquant 1"
                      isCaptain={
                        squad["attaque_2"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                  </Stack>
                  <Stack>
                    <div className="actions-area">
                      <Button onClick={handleSaveTeam}>
                        Sauvegarder équipe
                      </Button>
                      <Button onClick={cancelSelection}>
                        Supprimer la selection
                      </Button>
                    </div>
                  </Stack>
                </div>
                <div className="substitutes-section">
                  <div className="substitutes-section-header">Remplançants</div>
                  <Stack
                    sx={{ margin: "auto" }}
                    direction="row"
                    justifyContent={"space-evenly"}
                  >
                    <Player
                      position="goalkeeper_sub"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["goalkeeper_sub"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Goalkeeper"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Gardien de but"
                      isCaptain={
                        squad["goalkeeper_sub"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                    <Player
                      position="defense_sub"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["defense_sub"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Defense"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Défenseur"
                      isCaptain={
                        squad["defense_sub"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                    <Player
                      position="milieu_sub"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["milieu_sub"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Milieu"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Milieu"
                      isCaptain={
                        squad["milieu_sub"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                    <Player
                      position="attaque_sub"
                      setSelectedPlayer={setSelectedPlayer}
                      setPosition={handlePositionChange}
                      selectedPlayer={selectedPlayer}
                      squadSkeleton={squad["attaque_sub"]}
                      setHighlight={setHighlight}
                      highlight={highlight}
                      abstractPosition={"Attaque"}
                      handleShowDetails={handleShowDetails}
                      placeholder="Attaquant"
                      isCaptain={
                        squad["attaque_sub"].player_transfertmarkt_id ===
                        squad["captain"].player_transfertmarkt_id
                      }
                    ></Player>
                  </Stack>
                </div>
              </Grid>

              <Grid item xs={12} sm={4} lg={5} className="control-area">
                <Grid direction="column">
                  {validSquad && (
                    <div
                      className="team-status"
                      style={{ background: "#ffffff" }}
                    >
                      <Button
                        sx={{ margin: "5px" }}
                        color="secondary"
                        variant="contained"
                        onClick={handleExpressPlay}
                      >
                        Partie rapide
                      </Button>
                      <Button
                        sx={{ margin: "5px" }}
                        color="secondary"
                        variant="contained"
                        onClick={handleCreate}
                      >
                        Créer partie
                      </Button>
                    </div>
                  )}
                  <div className="team-status">
                    <div>
                      <div className="team-budget-label">Equipe</div>
                      <div className="team-budget">{teamName ?? ""}</div>
                    </div>
                    <div>
                      <div className="team-budget-label">Budget stratégie</div>
                      <div className="team-budget">
                        {deckBudget -
                          (userCards?.reduce(
                            (partialSum, a) => partialSum + a.price,
                            0
                          )??0)}
                      </div>
                    </div>
                  </div>
                  <div className="team-status">
                    <div>
                      <div className="team-budget-label">Budget</div>
                      <div className="team-budget">
                        {isNaN(getBudget()) ? 0 : getBudget()}
                      </div>
                    </div>
                    <div>
                      <div className="team-budget-label">Valeur titulaires</div>
                      <div className="team-budget">
                        {isNaN(getTeamStrenght().total_lineup)
                          ? 0
                          : getTeamStrenght().total_lineup}
                      </div>
                    </div>
                    <div>
                      <div className="team-budget-label">Score</div>
                      <div className="team-budget">{score > 0 ? score : 0}</div>
                    </div>
                  </div>
                  <div className="team-status">
                    <div>
                      <div className="team-budget-label">Defense</div>
                      <div className="team-budget">
                        {isNaN(getTeamStrenght().defense)
                          ? 0
                          : getTeamStrenght().defense}
                      </div>
                    </div>
                    <div>
                      <div className="team-budget-label">Milieu</div>
                      <div className="team-budget">
                        {isNaN(getTeamStrenght().milieu)
                          ? 0
                          : getTeamStrenght().milieu}
                      </div>
                    </div>
                    <div>
                      <div className="team-budget-label">Attaque</div>
                      <div className="team-budget">
                        {isNaN(getTeamStrenght().attack)
                          ? 0
                          : getTeamStrenght().attack}
                      </div>
                    </div>
                  </div>
                </Grid>

                <Box>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="basic tabs example"
                      textColor="secondary"
                      indicatorColor="secondary"
                    >
                      <Tab label="Transferts" {...a11yProps(0)} />
                      <Tab label="Ma Strategie" {...a11yProps(2)} />
                      <Tab label="Strategies Store" {...a11yProps(1)} />

                      {validSquad && <Tab label="Lobby" {...a11yProps(3)} />}
                      {validSquad && <Tab label="Ligues" {...a11yProps(4)} />}
                      {validSquad && (
                        <Tab label="Rencontres" {...a11yProps(5)} />
                      )}
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={1}>
                    <Typography align="left" variant={"h3"}>
                      {teamName}
                    </Typography>
                    <Divider></Divider>
                    <Typography align="left" variant={"h6"}>
                      Strategie d'équipe {userCards?.length}/9
                    </Typography>
                    <Divider></Divider>
                    <List>
                      {userCards?.map((userCard) => {
                        return (
                          <ListItem key={userCard.id}>
                            <Chip
                              icon={<AutoGraphIcon></AutoGraphIcon>}
                              label={userCard.card_name}
                              onDelete={() => handleDeleteCard(userCard)}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </TabPanel>
                  <TabPanel value={value} index={0}>
                    <Typography align="left" variant={"h3"}>
                      Transferts
                    </Typography>
                    <TableContainer className="players-table">
                      <Table sx={{ minWidth: "80%" }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell align="right">Rating</TableCell>
                            <TableCell align="right">Valeur</TableCell>
                            <TableCell align="right">Age</TableCell>
                            <TableCell align="right">Club</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {players?.map((player) => {
                            return (
                              <TableRow
                                key={player.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                onClick={() => {
                                  handleShowDetails(player, false);
                                }}
                              >
                                <TableCell scope="Stack">
                                  {player.player_name}
                                </TableCell>
                                <TableCell align="right">
                                  <Rating
                                    readOnly
                                    name="half-rating"
                                    defaultValue={convertToStars(
                                      convertToMad(player.market_value)
                                    )}
                                    precision={0.5}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  {convertToMad(player.market_value)}
                                </TableCell>
                                <TableCell align="right">
                                  {player.age}
                                </TableCell>
                                <TableCell align="right">
                                  <img
                                    style={{ width: "25px" }}
                                    src={player.player_team + "_shield.png"}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Typography align="left" variant={"h3"}>
                      Strategie
                    </Typography>
                    <ToggleButtonGroup
                      orientation="horizontal"
                      value={view}
                      exclusive
                      onChange={handleChangeView}
                    >
                      <ToggleButton value="module" aria-label="module">
                        <ViewModuleIcon />
                      </ToggleButton>
                      <ToggleButton value="list" aria-label="list">
                        <ViewListIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>

                    {view == "list" && (
                      <TableContainer className="cards-table">
                        <Table
                          sx={{ minWidth: "100%" }}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Categorie</TableCell>
                              <TableCell>Strategie</TableCell>
                              <TableCell>Prix</TableCell>
                              <TableCell align="right">Defense</TableCell>
                              <TableCell align="right">Milieu</TableCell>
                              <TableCell align="right">Attaque</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {cards?.map((card) => {
                              return (
                                <TableRow
                                  key={card.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  onClick={() => {
                                    handleShowCardsDetails(card);
                                  }}
                                >
                                  <TableCell align="right">
                                    {card.category.toUpperCase()}
                                  </TableCell>
                                  <TableCell scope="Stack">
                                    {card.card_name}
                                  </TableCell>
                                  <TableCell scope="Stack">
                                    {card.price}
                                  </TableCell>
                                  <TableCell align="right">
                                    {card.defense}
                                  </TableCell>
                                  <TableCell align="right">
                                    {card.milieu}
                                  </TableCell>
                                  <TableCell align="right">
                                    {card.attaques}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                    {view == "module" && (
                      <div className="cards-module-view">
                        {cards?.map((card) => {
                          return (
                            <StatsBadgeNoteSmall
                              onClick={() => {
                                handleShowCardsDetails(card);
                              }}
                              category={card.category.toUpperCase()}
                              value={card?.card_name}
                            ></StatsBadgeNoteSmall>
                          );
                        })}
                      </div>
                    )}
                  </TabPanel>

                  {validSquad && (
                    <TabPanel value={value} index={3}>
                      <Typography align="left" variant={"h3"}>
                        Jeu en ligne
                      </Typography>
                      <Container>
                        <Stack direction={"column"} sx={{ margin: "5px" }}>
                          <Button
                            sx={{ margin: "5px" }}
                            color="secondary"
                            variant="contained"
                            onClick={handleExpressPlay}
                          >
                            Partie rapide
                          </Button>
                          <Button
                            sx={{ margin: "5px" }}
                            color="secondary"
                            variant="contained"
                            onClick={handleCreate}
                          >
                            Créer partie
                          </Button>
                        </Stack>
                        <TableContainer className="cards-table">
                          <Table
                            sx={{ minWidth: "100%" }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>Joueurs</TableCell>
                                <TableCell>Partie</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {games?.map((game) => {
                                console.log(game);
                                return (
                                  <TableRow
                                    key={game.id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell>
                                      {game.online && circle}
                                    </TableCell>
                                    <TableCell>
                                      {game.homeTeam?.isOnline ||
                                      game.awayTeam?.isOnline
                                        ? "1/2"
                                        : "0/2"}
                                    </TableCell>
                                    <TableCell scope="Stack">
                                      Game #{game.id}
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={() =>
                                          handleJoin(game, game.id)
                                        }
                                      >
                                        {user.id == game.home_team ||
                                        user.id == game.away_team
                                          ? "Continue"
                                          : "Rejoindre"}
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Container>
                    </TabPanel>
                  )}
                  {validSquad && (
                    <TabPanel value={value} index={4}>
                      <Typography align="left" variant={"h3"}>
                        Ligues
                      </Typography>
                      <Container>
                        <Stack direction={"column"} sx={{ margin: "5px" }}>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={league}
                              onChange={handleLiguesChange}
                            >
                              {leagues?.map((ligue) => {
                                return (
                                  <MenuItem value={ligue.id}>
                                    {ligue.league_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <Button
                              sx={{ margin: "5px" }}
                              color="secondary"
                              variant="contained"
                              onClick={handleJoinLigues}
                            >
                              Rejoindre
                            </Button>
                          </FormControl>
                        </Stack>
                        <TableContainer>
                          <Table
                            sx={{ minWidth: "100%" }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Ranking</TableCell>
                                <TableCell>Team</TableCell>
                                <TableCell>Score</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {leagueRankings?.map((leagueRanking, i) => {
                                return (
                                  <TableRow
                                    key={leagueRanking.id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell scope="Stack">
                                      <b>{i + 1}</b>
                                    </TableCell>
                                    <TableCell>
                                      <b>{leagueRanking.team_name}</b>
                                    </TableCell>
                                    <TableCell>
                                      <b>{leagueRanking.score}</b>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                              {myRankings && (
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      background: "#895389 !important",
                                      color: "#ffffff !important",
                                    }}
                                    scope="Stack"
                                  >
                                    <b> {myRankings}</b>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      background: "#895389 !important",
                                      color: "#ffffff !important",
                                    }}
                                  >
                                    <b> {teamName}</b>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      background: "#895389 !important",
                                      color: "#ffffff !important",
                                    }}
                                  >
                                    <b>{score}</b>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Container>
                    </TabPanel>
                  )}
                  {validSquad && (
                    <TabPanel value={value} index={5}>
                      <Typography align="left" variant={"h3"}>
                        Historique
                      </Typography>
                      <Container>
                        <Historique user_id={user.id}></Historique>
                      </Container>
                    </TabPanel>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PlayerDrawer
        showData={showStats}
        setShowData={setShowStats}
        stats={stats}
        selectedPlayer={selectedPlayer}
        switchPlayer={switchPlayer}
        playerIn={playerIn}
        position={position}
        showCaptainSelection={showCaptainSelection}
      ></PlayerDrawer>
      <CardDrawer
        showData={showCardDetail}
        setShowData={setShowCardDetail}
        card={card}
        addCard={addCard}
      ></CardDrawer>

      <Dialog open={showSaveDialog} onClose={cancelSave}>
        <DialogTitle>Sauvegarder équipe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez renseigner un nom à votre équipe.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="team_name"
            label="Nom d'équipe"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleTeamName}
            value={teamName}
          />
        </DialogContent>
        <DialogActions>
          <div className="actions-area-save">
            <Button onClick={cancelSave}>Cancel</Button>
            <Button onClick={saveTeam}>Sauvegarder</Button>
          </div>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Home;
