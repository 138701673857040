import { useState } from "react";

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("x-auth-token");
    return tokenString;
  };
  const [token, setToken] = useState(getToken());
  const saveToken = (tokenString) => {
    localStorage.setItem("x-auth-token", tokenString);
    setToken(tokenString);
  };
  return {
    setToken: saveToken,
    token,
  };
}
