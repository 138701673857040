import * as React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import AssessmentIcon from "@mui/icons-material/Assessment";
import StyleIcon from "@mui/icons-material/Style";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";

export default function CustomizedTimeline(props) {
  const rows = [];
  if (props.awayPlayedCards)
    var awayCards = props.awayPlayedCards?.map((x) => {
      x.team = "away";
      return x;
    });
  if (props.homePlayedCards)
    var homeCards = props.homePlayedCards?.map((x) => {
      x.team = "home";
      return x;
    });

  let mergedCards = awayCards?.concat(homeCards);
  let sortedMergedCards = mergedCards?.sort(function (a, b) {
    return a.minute - b.minute;
  });

  for (let i = 0; i < sortedMergedCards?.length; i++) {
    rows.push(
      <>
        {sortedMergedCards[i].team == "home" && (
          <Alert
            onMouseEnter={(e) =>
              props.handlePopoverOpen(e, sortedMergedCards[i])
            }
            onMouseLeave={props.handlePopoverClose}
            icon={false}
            className="alert"
            severity="warning"
          >
            <AssessmentIcon className="timeline-icon" />
            {sortedMergedCards[i].minute +
              "' " +
              sortedMergedCards[i].card_name}
          </Alert>
        )}
        {sortedMergedCards[i].team == "away" && (
          <Alert
            onMouseEnter={(e) =>
              props.handlePopoverOpen(e, sortedMergedCards[i])
            }
            onMouseLeave={props.handlePopoverClose}
            icon={false}
            variant="filled"
            className="alert__away"
            severity="warning"
          >
            {sortedMergedCards[i].minute +
              "' " +
              sortedMergedCards[i].card_name}
            <AssessmentIcon className="timeline-icon" />
          </Alert>
        )}
      </>
    );
    for (let j = 0; j < props.timeline?.length; j++) {
      if (sortedMergedCards[i].minute == props.timeline[j].minute)
        rows.push(
          <>
            {props.timeline[j].team == "home" && (
              <Alert
                onMouseEnter={() => {}}
                onMouseLeave={() => {}}
                icon={false}
                className="event-alert"
              >
                {props.timeline[j].event.includes("Goal") ? (
                  <SportsSoccerIcon
                    className="timeline-icon"
                    color="success"
                  ></SportsSoccerIcon>
                ) : null}
                {props.timeline[j].event.includes("Rouge") ? (
                  <StyleIcon
                    className="timeline-icon"
                    color="error"
                  ></StyleIcon>
                ) : null}
                {props.timeline[j].event.includes("Jaune") ? (
                  <StyleIcon
                    className="timeline-icon"
                    sx={{ color: "yellow" }}
                    color="warning"
                  ></StyleIcon>
                ) : null}
                {props.timeline[j].minute + "' " + props.timeline[j].event}
              </Alert>
            )}
            {props.timeline[j].team == "away" && (
              <Alert
                onMouseEnter={() => {}}
                onMouseLeave={() => {}}
                icon={false}
                variant="filled"
                className="event-alert__away"
              >
                {props.timeline[j].minute + "' " + props.timeline[j].event}
                {props.timeline[j].event.includes("Goal") ? (
                  <SportsSoccerIcon
                    className="timeline-icon"
                    color="success"
                  ></SportsSoccerIcon>
                ) : null}
                {props.timeline[j].event.includes("Rouge") ? (
                  <StyleIcon
                    className="timeline-icon"
                    color="error"
                  ></StyleIcon>
                ) : null}
                {props.timeline[j].event.includes("Jaune") ? (
                  <StyleIcon
                    className="timeline-icon"
                    sx={{ color: "yellow" }}
                    color="warning"
                  ></StyleIcon>
                ) : null}
              </Alert>
            )}
          </>
        );
    }
  }
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      {rows}
    </Stack>
  );
}
