import "./FixturePresentation.scss";
import { Stack, ListItem } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EventsDrawer from "./EventsDrawer";

function FixturePresentation(props) {
  let navigate = useNavigate();
  const [showEvents, setShowEvents] = useState(false);

  const openAdmin = async () => {
    await setShowEvents(true);
  };
  return (
    <>
      <ListItem onClick={openAdmin} className="fixture-line">
        <Stack direction={"row"}>
          <div className="home-team">
            <div>
              {props.homeGoals > props.awayGoals ? (
                <EmojiEventsIcon style={{ color: "#FFD700" }}></EmojiEventsIcon>
              ) : (
                <EmojiEventsIcon
                  style={{ color: "transparent" }}
                ></EmojiEventsIcon>
              )}
            </div>
            <div className="team-name">{props.homeTeam}</div>
            <div className="team-score">{props.homeGoals ?? "-"}</div>
          </div>
          <div className="away-team">
            <div className="team-score">{props.awayGoals ?? "-"}</div>
            <div className="team-name">{props.awayTeam}</div>
            <div>
              {props.homeGoals < props.awayGoals ? (
                <EmojiEventsIcon style={{ color: "#FFD700" }}></EmojiEventsIcon>
              ) : (
                <EmojiEventsIcon
                  style={{ color: "transparent" }}
                ></EmojiEventsIcon>
              )}
            </div>
          </div>
        </Stack>
      </ListItem>
      <EventsDrawer
        showEvents={showEvents}
        setShowEvents={setShowEvents}
        timeline={props.fixtures[props.id]?.events_timeline}
        cards_played_home={props.fixtures[props.id]?.cards_played_home}
        cards_played_away={props.fixtures[props.id]?.cards_played_away}
      ></EventsDrawer>
    </>
  );
}

export { FixturePresentation };
