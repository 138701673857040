import { useState } from "react";

import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CategoryIcon from "@mui/icons-material/Category";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { StatsBadgeTitle, StatsBadgeNote } from "./StatsBadge";
import { Stack, Drawer, Typography, Button } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
function CardDrawer(props) {
  return (
    <Drawer
      anchor={"right"}
      open={props.showData}
      onClose={() => props.setShowData(false)}
    >
      <StatsBadgeTitle
        title={props.card?.card_name}
        icon={<QueryStatsIcon fontSize="large"></QueryStatsIcon>}
      ></StatsBadgeTitle>

      <Stack
        style={{
          width: "700px",
          height: "100%",
          padding: "12px",
          background: "black",
        }}
        direction={"column"}
      >
        <div className="statistics-section">
          <StatsBadgeNote
            category={"Category"}
            value={props.card?.category.toUpperCase()}
            icon={<CategoryIcon fontSize="large"></CategoryIcon>}
          ></StatsBadgeNote>
          <StatsBadgeNote
            category={"Prix"}
            value={props.card?.price}
            icon={<PaidIcon fontSize="large"></PaidIcon>}
          ></StatsBadgeNote>
          <Typography variant="h6" color={"#FFFFFF"}>
            {props.card?.card_description}
          </Typography>

          <StatsBadgeNote
            category={"Defense"}
            value={props.card?.defense}
            icon={<MilitaryTechIcon fontSize="large"></MilitaryTechIcon>}
          ></StatsBadgeNote>
          <StatsBadgeNote
            category={"Milieu"}
            value={props.card?.milieu}
            icon={<MilitaryTechIcon fontSize="large"></MilitaryTechIcon>}
          ></StatsBadgeNote>
          <StatsBadgeNote
            category={"Attaque"}
            value={props.card?.attaques}
            icon={<MilitaryTechIcon fontSize="large"></MilitaryTechIcon>}
          ></StatsBadgeNote>
        </div>

        <Stack>
          <div className="actions-area">
            <Button onClick={() => props.addCard(props.card)}>
              Ajouter au deck
            </Button>
          </div>
        </Stack>
      </Stack>
    </Drawer>
  );
}
export default CardDrawer;
