import "./StatsBadge.scss";
import {
  Stack,
  Drawer,
  List,
  ListItem,
  Typography,
  Popover,
  Box,
  CircularProgress,
  Divider,
} from "@mui/material";
import { useState } from "react";
import StadiumIcon from "@mui/icons-material/Stadium";
import PersonIcon from "@mui/icons-material/Person";
import SportsSoccerTwoToneIcon from "@mui/icons-material/SportsSoccerTwoTone";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import AdjustIcon from "@mui/icons-material/Adjust";
import StyleIcon from "@mui/icons-material/Style";
import BrowserNotSupportedIcon from "@mui/icons-material/BrowserNotSupported";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import GaugeChart from "react-gauge-chart";
import Gauge from "./Gauge";
function StatsBadgeTitle(props) {
  return (
    <ListItem className="player-stats-name-container">
      <Stack direction={"row"}>
        <Stack className="player-stats-icon-container" direction={"row"}>
          <div className="player-stats-icon">{props.icon}</div>
        </Stack>
        <Stack direction={"column"}>
          <Typography
            alignContent={"center"}
            variant="h5"
            component="div"
            fontWeight={"bold"}
            color="white"
            sx={{ margin: "auto" }}
          >
            {props.title}
          </Typography>
        </Stack>
      </Stack>
    </ListItem>
  );
}

function StatsBadgeNote(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Stack
      width={"25%"}
      direction={"row"}
      aria-owns={open ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      className="player-badge"
    >
      <div className="player-stats-header">
        <Typography sx={{ p: 1 }}>{props.category}</Typography>
      </div>

      <Stack className="player-stats-icon-container" direction={"row"}>
        <div className="player-stats-value">
          <Typography
            alignContent={"center"}
            fontWeight={"bold"}
            variant="h6"
            component="div"
          >
            {props.value ?? 0}
          </Typography>
        </div>
        <div className="player-stats-icon">{props.icon}</div>
      </Stack>
    </Stack>
  );
}

function StatsBadgeNoteSmall(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Stack
      width={"25%"}
      direction={"row"}
      aria-owns={open ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      className="player-badge"
      onClick={props.onClick}
    >
      <div className="player-stats-header">
        <Typography sx={{ p: 1 }}>{props.category}</Typography>
      </div>

      <Stack className="player-stats-icon-container" direction={"row"}>
        {props.icon && <div className="player-stats-icon">{props.icon}</div>}
        <div className="player-stats-value">
          <Typography
            alignContent={"center"}
            fontWeight={"bold"}
            variant="p"
            component="div"
          >
            {props.value ?? 0}
          </Typography>
        </div>
      </Stack>
    </Stack>
  );
}
function StatsBadgeName(props) {
  return (
    <Stack direction={"row"}>
      <Stack className="player-stats-icon-container" direction={"row"}>
        <div className="player-stats-icon">{props.icon}</div>
      </Stack>
      <Stack direction={"column"}>
        <Typography
          alignContent={"center"}
          fontWeight={"bold"}
          variant="h6"
          component="div"
        >
          {props.category}
        </Typography>
        <Typography variant="h6" gutterBottom component="div">
          {props.value}
        </Typography>
      </Stack>
    </Stack>
  );
}

function StatsBadgeRatio(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Stack
      aria-owns={open ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      width={"25%"}
      direction={"column"}
      alignItems={"center"}
      className="player-badge"
    >
      <div className="player-stats-header">
        <Typography sx={{ p: 1 }}>{props.category}</Typography>
      </div>
      <Stack className="player-stats-icon-container">
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "26px",
              color: "#FFF",
            }}
          >
            {props.value ? props.value + "%" : "0%"}
          </Box>
          <CircularProgress
            sx={{
              color: "#895389",
              padding: "4px",
            }}
            variant="determinate"
            value={props.value}
          />
        </Box>
      </Stack>
    </Stack>
  );
}
function StatsBadgeGauge(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const chartStyle = {
    width: 100,
  };
  const open = Boolean(anchorEl);
  console.log(props.value);
  return (
    <Stack
      aria-owns={open ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      width={"25%"}
      direction={"column"}
      alignItems={"center"}
      className="player-badge player-badge-gauge"
    >
      <div className="player-stats-header">
        <Typography sx={{ p: 1 }}>{props.category}</Typography>
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "26px",
          color: "#FFF",
        }}
      >
        <GaugeChart
          animate={false}
          nrOfLevels={30}
          arcsLength={[0.3, 0.5, 0.2]}
          colors={["#5BE12C", "#F5CD19", "#EA4228"]}
          percent={props.value}
          arcPadding={0.02}
          style={chartStyle}
          className={"data-gauge"}
        />
      </Box>
    </Stack>
  );
}
function StatsBadgeGaugeRound(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const chartStyle = {
    width: 100,
  };
  const open = Boolean(anchorEl);
  console.log(props.value);
  return (
    <Stack
      aria-owns={open ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      width={"25%"}
      direction={"column"}
      alignItems={"center"}
      className="player-badge player-badge-gauge"
    >
      <div className="player-stats-header">
        <Typography sx={{ p: 1 }}>{props.category}</Typography>
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "26px",
          color: "#FFF",
        }}
      >
        <Gauge value={props.value ?? 0} category={props.category} />
      </Box>
    </Stack>
  );
}
export {
  StatsBadgeTitle,
  StatsBadgeNote,
  StatsBadgeRatio,
  StatsBadgeName,
  StatsBadgeNoteSmall,
  StatsBadgeGauge,
  StatsBadgeGaugeRound,
};
