import "./Fixtures.scss";
import {
  Stack,
  List,
  MenuItem,
  Select,
  FormControl,
  Box,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { FixturePresentation } from "./FixturePresentation";
import { mainUrl } from "../../env";
import useToken from "../../useToken";

function Historique(props) {
  const [fixtures, setFixtures] = useState([]);

  const { token, setToken } = useToken();

  useEffect(() => {
    axios.defaults.headers.common["x-auth-token"] = token;
  }, [token]);

  useEffect(() => {
    axios
      .get(
        mainUrl + "/api/fixtures/getfixturesByUser?user_id=" + props.user_id,
        {}
      )
      .then((res) => {
        const data = res.data.fixtures;
        setFixtures(data);
      });
  }, []);
  console.log(fixtures);
  return (
    <>
      <List>
        {fixtures &&
          fixtures?.map((fixture, index) => {
            return (
              <FixturePresentation
                id={index}
                fixtures={fixtures}
                homeTeam={fixture?.fantasyhomeTeam?.team_name}
                awayTeam={fixture?.fantasyawayTeam?.team_name}
                homeGoals={fixture?.score_home}
                awayGoals={fixture?.score_away}
              ></FixturePresentation>
            );
          })}
      </List>
    </>
  );
}

export default Historique;
