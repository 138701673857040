import "./ForgottenPassword.scss";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  TextField,
  Link,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { mainUrl } from "../../env";
function ForgottenPassword(props) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const requestReset = async () => {
    try {
      await axios
        .post(mainUrl + "/api/reset/", {
          email: email,
        })
        .then((res) => {
          if (res.status == 200 && res.data.user) {
            setOpen(true);
          } else setError(true);
        });
    } catch (e) {
      setError(true);
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", height: "100%" }}
      >
        <Grid
          item
          sx={{ maxWidth: "70rem", width: "100%", backgroundColor: "#fff" }}
        >
          <Grid
            container
            sx={{
              boxShadow: { sm: "0 0 5px #ddd" },
              py: "6rem",
              px: "1rem",
            }}
          >
            <Grid
              item
              container
              justifyContent="space-between"
              rowSpacing={5}
              sx={{
                marginInline: "auto",
              }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ borderRight: { sm: "1px solid #ddd" } }}
                style={{
                  margin: "auto",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ display: "flex", alignItems: "center" }}
                  onSubmit={() => {}}
                >
                  <Typography
                    variant="h4"
                    component="h1"
                    sx={{ textAlign: "center", mb: "1.5rem", color: "#895389" }}
                  >
                    Mot de passe oublié
                  </Typography>

                  <TextField
                    sx={{ mb: "12px", width: "80%" }}
                    id="outlined-basic"
                    label="Email"
                    type="email"
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                  />

                  <Button
                    loading={false}
                    variant="contained"
                    sx={{
                      py: "0.8rem",
                      mt: 2,
                      width: "80%",
                      marginInline: "auto",
                    }}
                    onClick={requestReset}
                  >
                    Confirmer
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                <img
                  src="./botola_pro.png"
                  style={{ width: "30%", margin: "auto" }}
                  loading="lazy"
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Stack sx={{ mt: "3rem", textAlign: "center" }}>
                <Link
                  href={"/signup"}
                  variant="contained"
                  sx={{
                    cursor: "pointer",
                    marginInline: "auto",
                  }}
                >
                  S'inscrire
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Une erreur s'est produite
        </Alert>
      </Snackbar>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Veuillez vérifier votre email.
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ForgottenPassword;
