import "./Page.scss";
import { useEffect, useState } from "react";
import {
  Stack,
  ListItem,
  MenuItem,
  Select,
  FormControl,
  Box,
  InputLabel,
  Container,
} from "@mui/material";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import Home from "./Home";
import Footer from "../Footer/Footer";

function Privacy(props) {
  let navigate = useNavigate();
  const toHome = () => {
    navigate("/");
  };
  return (
    <>
      <Stack>
        <AppBar position="static">
          <Toolbar className="header-menu">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toHome}
            >
              <HomeIcon />
            </IconButton>
            <img
              style={{ width: "370px", verticalAlign: "middle" }}
              src="./logo.png"
            />
            <Button onClick={() => navigate("/")} color="inherit">
              Connexion
            </Button>
          </Toolbar>
        </AppBar>
      </Stack>
      <Stack textAlign={"left"} padding={"25px"}>
        <h1>Politique de confidentialité pour la ligue</h1>
        <p>
          Chez The League, accessible depuis Theleague.ma, l'une de nos
          principales priorités est la confidentialité de nos visiteurs. Ce
          document de politique de confidentialité contient des types
          d'informations qui sont collectées et enregistrées par The League et
          comment nous les utilisons.
        </p>
        <p>
          Si vous avez des questions supplémentaires ou souhaitez plus
          d'informations sur notre politique de confidentialité, n'hésitez pas à
          nous contacter.
        </p>
        <p>
          Cette politique de confidentialité s'applique uniquement à nos
          activités en ligne et est valable pour les visiteurs de notre site Web
          en ce qui concerne les informations qu'ils partagent et/ou collectent
          dans The League. Cette politique ne s'applique pas aux informations
          collectées hors ligne ou via des canaux autres que ce site Web. Notre
          politique de confidentialité a été créée à l'aide du{" "}
          <a href="https://www.privacypolicygenerator.info/">
            générateur gratuit de politique de confidentialité
          </a>
          .
        </p>
        <h2>Consentement</h2>
        <p>
          En utilisant notre site Web, vous consentez par la présente à notre
          politique de confidentialité et acceptez ses conditions.
        </p>
        <h2>Informations que nous collectons</h2>
        <p>
          Les informations personnelles que vous êtes invité à fournir, et les
          raisons pour lesquelles vous êtes invité à les fournir, vous seront
          précisées au moment où nous vous demanderons de fournir vos
          informations personnelles.
        </p>
        <p>
          Si vous nous contactez directement, nous pouvons recevoir des
          informations supplémentaires vous concernant telles que votre nom,
          votre adresse e-mail, votre numéro de téléphone, le contenu du message
          et/ou des pièces jointes que vous pouvez nous envoyer, et toute autre
          information que vous pouvez choisir de fournir .
        </p>
        <p>
          Lorsque vous créez un compte, nous pouvons vous demander vos
          coordonnées, y compris des éléments tels que le nom, le nom de
          l'entreprise, l'adresse, l'adresse e-mail et le numéro de téléphone.
        </p>
        <h2>Comment nous utilisons vos informations</h2>
        <p>
          Nous utilisons les informations que nous collectons de différentes
          manières, notamment pour :
        </p>
        <ul>
          <li>Fournir, exploiter et maintenir notre site Web</li>
          <li>Améliorer, personnaliser et développer notre site Web</li>
          <li>
            Comprendre et analyser la façon dont vous utilisez notre site Web
          </li>
          <li>
            Développer de nouveaux produits, services, fonctionnalités et
            fonctionnalités
          </li>
          <li>
            Communiquer avec vous, directement ou par l'intermédiaire de l'un de
            nos partenaires, y compris pour le service client, pour vous fournir
            des mises à jour et d'autres informations relatives au site Web, et
            à des fins de marketing et de promotion
          </li>
          <li>Vous envoyer des e-mails</li>
          <li>Détecter et prévenir les fraudes</li>
        </ul>
        <h2>Fichiers journaux</h2>
        <p>
          La League suit une procédure standard d'utilisation des fichiers
          journaux. Ces fichiers enregistrent les visiteurs lorsqu'ils visitent
          des sites Web. Toutes les sociétés d'hébergement le font et font
          partie de l'analyse des services d'hébergement. Les informations
          collectées par les fichiers journaux incluent les adresses IP
          (protocole Internet), le type de navigateur, le fournisseur d'accès
          Internet (FAI), l'horodatage, les pages de renvoi/sortie et
          éventuellement le nombre de clics. Ceux-ci ne sont liés à aucune
          information personnellement identifiable. Le but de l'information est
          d'analyser les tendances, d'administrer le site, de suivre les
          mouvements des utilisateurs sur le site Web et de recueillir des
          informations démographiques.
        </p>
        <h2>Cookies et balises Web</h2>
        <p>
          Comme tout autre site Web, The League utilise des "cookies". Ces
          cookies sont utilisés pour stocker des informations, y compris les
          préférences des visiteurs et les pages du site Web auxquelles le
          visiteur a accédé ou visité. Les informations sont utilisées pour
          optimiser l'expérience des utilisateurs en personnalisant le contenu
          de notre page Web en fonction du type de navigateur des visiteurs
          et/ou d'autres informations.
        </p>
        <h2>Cookie Google DoubleClick DART</h2>
        <p>
          Google est l'un des fournisseurs tiers de notre site. Il utilise
          également des cookies, connus sous le nom de cookies DART, pour
          diffuser des annonces aux visiteurs de notre site en fonction de leur
          visite sur www.website.com et d'autres sites sur Internet. Cependant,
          les visiteurs peuvent choisir de refuser l'utilisation des cookies
          DART en visitant la politique de confidentialité du réseau
          publicitaire et de contenu Google à l'URL suivante -{" "}
          <a href="https://policies.google.com/technologies/ads">
            https:/ /policies.google.com/technologies/annonces
          </a>
        </p>
        <h2>Nos partenaires publicitaires</h2>
        <p>
          Certains annonceurs sur notre site peuvent utiliser des cookies et des
          balises Web. Nos partenaires publicitaires sont listés ci-dessous.
          Chacun de nos partenaires publicitaires a sa propre politique de
          confidentialité pour ses politiques sur les données des utilisateurs.
          Pour un accès plus facile, nous avons établi un lien hypertexte vers
          leurs politiques de confidentialité ci-dessous.
        </p>
        <ul>
          <li>
            <p>Google</p>
            <p>
              <a href="https://policies.google.com/technologies/ads">
                https://policies.google.com/technologies/ads
              </a>
            </p>
          </li>
        </ul>

        <h2>Politiques de confidentialité des partenaires publicitaires</h2>

        <p>
          Vous pouvez consulter cette liste pour trouver la politique de
          confidentialité de chacun des partenaires publicitaires de The League.
        </p>

        <p>
          Les serveurs publicitaires ou réseaux publicitaires tiers utilisent
          des technologies telles que les cookies, JavaScript ou les balises Web
          qui sont utilisées dans leurs publicités et liens respectifs qui
          apparaissent sur The League, qui sont envoyés directement au
          navigateur des utilisateurs. Ils reçoivent automatiquement votre
          adresse IP lorsque cela se produit. Ces technologies sont utilisées
          pour mesurer l'efficacité de leurs campagnes publicitaires et/ou pour
          personnaliser le contenu publicitaire que vous voyez sur les sites Web
          que vous visitez.
        </p>

        <p>
          Notez que The League n'a aucun accès ni aucun contrôle sur ces cookies
          qui sont utilisés par des publicités tierces
        </p>
      </Stack>
      <Footer></Footer>
    </>
  );
}

export { Privacy };
